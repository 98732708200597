import { useCallback, useMemo, useState } from "react";
import TaskService, { TaskFieldId } from "../../../services/TaskSerivce";
import { TranslationService } from "../../../services/TranslationService";
import { useEndpointData } from "../../../utils/TableUtils";
import { TaskListRequest } from "../../task/entities/TaskListRequest";
import CompanyService from "../../../services/CompanyService";
import AdvancedFilterService, { Filter } from "../../../services/AdvancedFilterService";
import FilterService, { FilterEntity, FilterOption } from "../../../services/FilterService";
import moment from "moment";
import { formatDateDigits, formatIntizaDate, formatTimeIfNonZero } from "../../../utils/FormatUtils";
import { DateService } from "../../../services/DateService";
import { TaskEntities } from "../../task/entities/TaskListResponse";
import { isNullOrWhitespace, OptionalMap } from "../../../utils/Utils";
import FloatingPanelService from "../../shared/FloatingPanel";
import TaskDetail from "../../task/components/TaskDetail";
import { TaskStatus } from "../../task/entities/TaskStatus";
import Loading from "../../shared/components/Loading";
import TooltipComponent from "../../shared/TooltipComponent";
import notCheckedIcon from "../../../assets/img/not-checked-icon.svg";
import { Link } from "react-router-dom";

const CalendarTask = () => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedMonth, setSelectedMonth] = useState(moment().startOf("day").toDate());
    const [request, setRequest] = useState(getDefaultFilter);
    const [onlyMyTasks, setOnlyMyTasks] = useState(true);
    const endpoint = useCallback(() => TaskService.getList(request), [request]);
    const { response, loading } = useEndpointData(endpoint);

    const changeMonth = (date: Date) => {
        if (date.getMonth() !== selectedMonth.getMonth()) {
            changeMonthTo(request, date);
            setRequest({ ...request });
            setSelectedMonth(date);
            setSelectedDate(null);
        }
    };

    const changeDate = (date: Date) => {
        changeMonth(date);
        setSelectedDate(date);
    };

    const monthIssuesToShow = useMemo(() => {
        return response?.list
            .filter(x => !onlyMyTasks || x.ResponsibleUserID?.toString() === CompanyService.getUserid()) ?? [];
    }, [onlyMyTasks, response?.list]);

    const dayIssuesToShow = useMemo(() => {
        const date = moment(selectedDate);
        return monthIssuesToShow
            .filter(x => (!selectedDate || moment(x.dueDate).isSame(date, "day")));
    }, [monthIssuesToShow, selectedDate]);

    return (
        <div className="card">
            <div className="card-body">
                <div className="row flex-nowrap">
                    <div className="col-auto">
                        <CalendarView selectedDate={selectedDate} selectedMonth={selectedMonth} changeDate={changeDate} changeMonth={changeMonth} issues={monthIssuesToShow} />
                    </div>
                    <div className="col">
                        <div className="p-0 h-100" style={{ minHeight: "306px" }}>
                            <section className="h-100">
                                {loading ? <div className="d-flex justify-content-center h-100"><Loading /></div> :
                                    <>
                                        <ul className="nav nav-pills" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className={"nav-link " + (onlyMyTasks ? "active" : "")} type="button" role="tab" onClick={() => setOnlyMyTasks(true)} >
                                                    <p>{TranslationService.translate.MyTasks}</p><span className="rounded"></span>
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={"nav-link " + (!onlyMyTasks ? "active" : "")} type="button" role="tab" onClick={() => setOnlyMyTasks(false)} >
                                                    <p>{TranslationService.translate.AllTasks}</p><span className="rounded"></span>
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="tab-content tasks" id="pills-tab-content">
                                            <TaskList tasks={dayIssuesToShow} />
                                        </div>
                                    </>
                                }
                            </section>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

function getDefaultFilter() {
    const taskListRequest = new TaskListRequest(null, null, CompanyService.getAdditionalDefinitions());
    taskListRequest.pagesize = 600;
    changeMonthTo(taskListRequest, new Date());
    return taskListRequest;
}

function changeMonthTo(request: TaskListRequest, date: Date) {
    const filterOptions = FilterService.GetFiltersForEntity(FilterEntity.Task);
    const filters = AdvancedFilterService.parseFilters(request.filter, [filterOptions]);
    const dateFilter: Filter = filters.find(x => x.id === TaskFieldId.Date.toString()) ?? getDefaultTaskDateFilter(filterOptions);
    const newFilters = filters.filter(x => x.id === TaskFieldId.Date.toString()) as Filter[];
    newFilters.push(dateFilter);

    const startOfMonth = moment(date).startOf("month").toDate();
    const endOfMonth = moment(date).endOf("month").toDate();
    dateFilter.value = formatIntizaDate(startOfMonth) + "-" + formatIntizaDate(endOfMonth);
    request.filter = decodeURIComponent(AdvancedFilterService.filtersToRequestString(newFilters));
}

function getDefaultTaskDateFilter(filterOption: FilterOption): Filter {
    const definition = filterOption.definitions.find(x => x.Field === TaskFieldId.Date.toString())!;
    return {
        definition,
        kind: definition.Type,
    };
}

const CalendarView = ({ selectedDate, selectedMonth, changeDate, changeMonth, issues }: { selectedDate: Date | null, selectedMonth: Date, changeDate: (date: Date) => void, changeMonth: (date: Date) => void, issues: TaskEntities.ListItem[] }) => {
    const startOfMonth = moment(selectedMonth).startOf("month");
    const endOfMonth = moment(selectedMonth).endOf("month");
    const issuesDates = issues.map(x => moment(x.dueDate));
    const thisMonthDaysClasses = Array.from({ length: endOfMonth.daysInMonth() + 1 }).map((_, i) => {
        const date = moment(selectedMonth).date(i);
        if (date.isSame(moment(selectedDate), "day")) {
            return "selected";
        }
        return issues.some((_, i) => issuesDates[i].isSame(date, "day")) ? "active" : "";
    });
    const prevMonthDay = startOfMonth.clone();
    const prevMonthDays = Array.from({ length: startOfMonth.weekday() }).map(() => prevMonthDay.add(-1, "days").toDate()).reverse();
    const nextMonthDays = Array.from({ length: 6 - endOfMonth.weekday() }).map((_, i) => i + 1);

    const advanceMonth = (direction: number) => {
        const newDate = moment(selectedMonth)
            .startOf("month")
            .add(direction, "month")
            .toDate();
        changeMonth(newDate);
    };

    return (
        <div className="calendar">
            <div className="header">
                <div className="d-flex gap-2 align-item-center">
                    <div className="year">{selectedMonth.getFullYear()}</div>
                    <div className="month">{DateService.getMonthName(selectedMonth)}</div>
                </div>
                <div className="actions">
                    <button className="btn btn-link" type="button" onClick={() => advanceMonth(-1)}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                    <button className="btn btn-link" type="button" onClick={() => advanceMonth(1)}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>
            </div>
            <div className="grid-container">
                <div className="grid-item day">{TranslationService.translate.Sunday}</div>
                <div className="grid-item day">{TranslationService.translate.Monday}</div>
                <div className="grid-item day">{TranslationService.translate.Tuesday}</div>
                <div className="grid-item day">{TranslationService.translate.Wednesday}</div>
                <div className="grid-item day">{TranslationService.translate.Thursday}</div>
                <div className="grid-item day">{TranslationService.translate.Friday}</div>
                <div className="grid-item day">{TranslationService.translate.Saturday}</div>
                {prevMonthDays.map(x =>
                    <div className="grid-item" key={x.getTime()}><button className="blocked">{x.getDate()}</button></div>)}
                {thisMonthDaysClasses.slice(1).map((x, i) =>
                    <div className="grid-item" key={i}><button className={x} onClick={() => changeDate(startOfMonth.clone().add(i, "days").toDate())}>{i + 1}</button></div>)}
                {nextMonthDays.length !== 7 && nextMonthDays.map(x =>
                    <div className="grid-item" key={x}><button className="blocked">{x}</button></div>)}
            </div>
        </div>
    );
};

const TaskList = ({ tasks }: { tasks: TaskEntities.ListItem[] }) => {
    const getName = (userId: number | string) => {
        return CompanyService.getUsers().find(x => x.Id === userId.toString())?.Value;
    };
    const openTask = (task: TaskEntities.ListItem) => {
        FloatingPanelService.showPanel({
            children: <TaskDetail taskId={task.TaskID} clientId={task.PersonID} creatorUserId={task.CreatorUserID} dateCreated={task.Created} reload={() => undefined} />,
            title: TranslationService.translate.Task,
            position: "center",
            width: 700,
            height: 700,
        });
    };
    return (
        <ul className="d-flex flex-column">
            {tasks.map(x =>
                <li className="historyCard" key={x.TaskID}>
                    <div className="row">
                        <div className="col-auto pe-0">
                            <div className="icon pointer" onClick={() => openTask(x)}>
                                {x.status === TaskStatus.Fulfilled ? <img className="img-fluid" src="/static/media/taskIcon.dfcfd10f.svg" alt="icon comment" />
                                    : <img className="img-fluid" src={notCheckedIcon} alt="icon comment" />}
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex align-items-center justify-content-between pointer" onClick={() => openTask(x)}>
                                <div className="type d-flex align-items-center flex-shrink-0">
                                    <div className="type d-flex align-items-center flex-shrink-0">
                                        {TranslationService.translate.Task}
                                        <Link to={`/${TranslationService.currentLanguage}/client/details?id=${x.PersonID}`}>
                                            <span className="creator"><span className="ms-1">{x.ResponsibleUserID ? TranslationService.translate.EmailTo.toLocaleLowerCase() : (TranslationService.translate.CreatedFem.toLocaleLowerCase() + " " + TranslationService.translate.By.toLocaleLowerCase())}</span> {getName(x.ResponsibleUserID ?? x.CreatorUserID!)}
                                            </span> -
                                        </Link>
                                        <div className="ms-2 d-flex align-items-center gap-2">
                                            {x.HasAttachments &&
                                                <TooltipComponent title={TranslationService.translate.Attachments}>
                                                    <i className="fa-light fa-paperclip text-grey"></i>
                                                </TooltipComponent>}
                                            {!isNullOrWhitespace(x.Tags) &&
                                                <TooltipComponent title={x.Tags}>
                                                    <i className="fa-light fa-tags text-grey"></i>
                                                </TooltipComponent>}
                                        </div>
                                    </div>
                                    <div className="truncate-lh-1 px-1 me-auto">
                                        <TooltipComponent title={x.Description}>
                                            {x.Description}
                                        </TooltipComponent>
                                    </div>
                                    <div className="dateSecondary flex-shrink-0">{OptionalMap(new Date(x.dueDate), x => formatDateDigits(x) + " " + formatTimeIfNonZero(x))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>)}
            {tasks.length === 0 &&
                <div className={"h-50 d-flex align-items-center justify-content-center h-100"} style={{ minHeight: 200 }}>
                    <div className="messageSwichContent text-center">
                        <p className="lead">{TranslationService.translate.NoTasksInThisPeriod}</p>
                    </div>
                </div >}
        </ul>
    );
};

export default CalendarTask;