import React from "react"
import { TranslationService } from "../../../services/TranslationService";
import FloatingPanelService from "../FloatingPanel"


export const FloatingPanelFooter = (props: React.PropsWithChildren<{ showDelete?: boolean, isDeleting?: boolean, onDeleteClick?: () => void }>) => {
    const { translate } = TranslationService;

    const delClick = () => {
        if (props.onDeleteClick) {
            props.onDeleteClick();
        }
    }
    return (
        <nav className="floatingFooter">
            <div className="row">
                {props.showDelete &&
                    <div className="col text-start">
                        <button type="button" className="btn btn-danger" onClick={delClick}>
                            {translate.Delete}
                            {props.isDeleting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </button>
                    </div>
                }
                <div className="col">
                    <div className="d-flex justify-content-end gap-2">
                        <button type="button" className="btn btn-none" onClick={FloatingPanelService.hidePanel}>{translate.Close}</button>
                        {props.children}
                    </div>
                </div>
            </div>
        </nav>
    )
}