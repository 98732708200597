import { TranslationService } from "../../services/TranslationService";
import TabPosition from "../shared/TabPosition";
import CurrencyConfiguration from "./Configuration/CurrencyConfiguration";
import DashboardConfiguration from "./Configuration/DashboardPanelConfiguration";
import EmailTemplateConfiguration from "./Configuration/EmailTemplateConfiguration";
import OtherConfiguration from "./Configuration/OtherConfiguration";
import VerticalMenu from "../shared/components/VerticalMenu";
import ImportTemplateConfiguration from "./Configuration/ImportTemplate/ImportTemplateConfiguration";
import TransactionType from "./Configuration/TransactionType";
import ActivityType from "./Configuration/ActivityType";
import InvoiceStatus from "./Configuration/InvoiceStatus";
import DataCompanyConfiguration from "./Configuration/DataCompanyConfiguration";
import TagsConfiguration from "./Configuration/TagsConfiguration";
import SMSConfiguration from "./Configuration/SMSConfiguration";
import { ConfigurationProvider } from "./ConfigurationContext";
import WhatsappTemplateConfiguration from "./Configuration/WhatsappTemplateConfiguration";
import CompanyService from "../../services/CompanyService";
import { ClientFieldConfiguration, InvoiceFieldConfiguration, PaymentFieldConfiguration } from "./Configuration/FieldConfiguration/Fields/EntityFieldConfiguration";
import ImportLog from "./Configuration/ImportLog";

const CompanyConfiguration: React.FC = () => {
    const { translate } = TranslationService;

    let tabs = [
        { title: translate.Company, component: DataCompanyConfiguration },
        { title: translate.ClientFields, component: ClientFieldConfiguration, hash: "ClientFields" },
        { title: translate.InvoiceFields, component: InvoiceFieldConfiguration, hash: "InvoiceFields" },
        { title: translate.PaymentFields, component: PaymentFieldConfiguration, hash: "PaymentFields" },
        { title: translate.Currencies, component: CurrencyConfiguration },
        { title: translate.Tags, component: TagsConfiguration },
        { title: translate.ImportLog, component: ImportLog },
        { title: translate.ImportTemplates, component: ImportTemplateConfiguration },
        { title: translate.EmailTemplates, component: EmailTemplateConfiguration },
    ];

    if (CompanyService.canShowWhatsApp()) {
        tabs.push({ title: translate.WhatsAppTemplates, component: WhatsappTemplateConfiguration });
    }

    tabs = [
        ...tabs,
        { title: translate.SMS, component: SMSConfiguration },
        { title: "Dashboard", component: DashboardConfiguration },
        {
            title: translate.Other, component: () =>
                <ConfigurationProvider>
                    <OtherConfiguration />
                </ConfigurationProvider>
        },
        { title: translate.InvoiceStatus, component: InvoiceStatus },
        { title: translate.ActivityTypes, component: ActivityType },
        { title: translate.PaymentTypes, component: TransactionType }
    ];

    return (
        <TabPosition module="Configuration">
            <div className="main-layout-container">
                <div className="main-panel configuration">
                    <VerticalMenu items={tabs} />
                </div>
            </div>
        </TabPosition>
    );
};

export default CompanyConfiguration;