import { CSSProperties, useCallback, useEffect, useState } from "react";
import CompanyService, { Entities } from "../../../../../services/CompanyService";
import FieldConfigurationService, { AdditionalDefinitionsListResponse } from "../../../../../services/FieldConfigurationService";
import { TranslationService } from "../../../../../services/TranslationService";
import { ToastService } from "../../../../shared/bootstrap/Toast";
import ErrorMessage from "../../../../shared/components/Error";
import Loading from "../../../../shared/components/Loading";
import FloatingPanelService from "../../../../shared/FloatingPanel";
import AdditionalDefinitionsEdit from "../AdditionalDefinitionsEdit";
import OrderableTable from "../OrderableTable";
import { CompanyAuth } from "../../../../../entities/login/CompanyAuth";
import { Switch } from "../../../../../utils/Utils";
import { EntityFieldItem } from "../EntityFieldItem";
import { SearchBar } from "../../SearchBar";

type FieldType = CompanyAuth.ConfigItem | AdditionalDefinitionsListResponse.Item;
const EntityFieldConfiguration = ({ entity }: { entity: Entities }) => {
    const { translate } = TranslationService;
    const [valueSearch, setValueSearch] = useState("");
    const [response, setResponse] = useState<FieldType[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const requestData = async () => {
        setError(false);
        const result = await FieldConfigurationService.getAdditionalDefinitionList();
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            setError(true);
            setLoading(false);
            return;
        }
        const fields = [
            ...CompanyService.getConfigItems().filter(x => x.Entity === entity && x.field !== -27),
            ...result.list.filter(x => x.Entity === entity),
        ];
        const getValue = <T,>(field: FieldType, configExtractor: (x: CompanyAuth.ConfigItem) => T, additionalExtractor: (x: AdditionalDefinitionsListResponse.Item) => T) =>
            "AdditionalDefinitionID" in field ? additionalExtractor(field) : configExtractor(field);
        const fieldsOrder = Switch(
            [entity === Entities.Client, CompanyService.getPersonSortedFields],
            [entity === Entities.Invoice, CompanyService.getIoSortedFields],
            [entity === Entities.Payment, ""],
        ) ?? "";
        const onlySorted =
            fieldsOrder
                .split(",")
                .map(x => fields.find(y => getValue(y, x => x.field, x => x.AdditionalDefinitionID).toString() === x)!)
                .filter(x => x !== undefined);
        const sorted = [...onlySorted, ...fields.filter(x => !onlySorted.includes(x))];
        if (entity === Entities.Payment) {
            sorted.unshift(...[
                TranslationService.translate.Date,
                TranslationService.translate.PaymentType,
                TranslationService.translate.Amount,
                TranslationService.translate.Observations
            ].map((x, i) => ({
                field: i,
                name: x,
                value: null,
                IncludeInList: true,
                IncludeInMail: true,
                ShowAlways: true,
                IncludeInClientExport: true,
                Entity: entity,
                ManualEdit: true,
                IncludeMainCurrency: true,
                ShowDueDays: true,
                ExcludeInFilters: true,
                Whatsapp: true,
                IncludeInCustomerPortal: true,
            })));
        }
        setResponse(sorted);
        setLoading(false);
        setError(false);
    };
    const requestDataCallback = useCallback(requestData, [entity]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const showAdditionalDefinitionsEditModal = () => {
        FloatingPanelService.showPanel({
            children: <AdditionalDefinitionsEdit reload={requestDataCallback} entityId={entity} />,
            title: translate.AdditionalDefinitionss,
            width: 600,
            height: 800,
            position: "center",
        });
    };

    const onSort = (items: FieldType[]) => {
        const getValue = <T,>(field: FieldType, configExtractor: (x: CompanyAuth.ConfigItem) => T, additionalExtractor: (x: AdditionalDefinitionsListResponse.Item) => T) =>
            "AdditionalDefinitionID" in field ? additionalExtractor(field) : configExtractor(field);
        const ids = items
            .map(y => getValue(y, x => x.field, x => x.AdditionalDefinitionID));
        FieldConfigurationService.setFieldsOrder(ids, entity);
    };

    const headers: { title: string, style?: undefined | CSSProperties }[] = [
        { title: translate.Field },
        { title: translate.Format },
        { title: translate.Identifier, style: { textAlign: "center" } },
        { title: translate.CustomerSite, style: { textAlign: "center" } },
        { title: "" }];

    if (entity !== Entities.Client) {
        headers.splice(3, 1);
        if (entity === Entities.Payment) {
            headers.splice(2, 1);
        }
    }

    if (loading) {
        return (<Loading />);
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />);
    }
    const title = Switch(
        [entity === Entities.Client, TranslationService.translate.ClientFields],
        [entity === Entities.Invoice, TranslationService.translate.InvoiceFields],
        [entity === Entities.Payment, TranslationService.translate.PaymentFields],
    );

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{title}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showAdditionalDefinitionsEditModal()}><i className="fal fa-plus" /> {translate.CreateNewField}</button>
                </div>
            </div>
            <SearchBar onSearch={setValueSearch} labelSearch={translate.Search + " " + title} />
            <div className="col-lg-12">
                <OrderableTable data={response} RowComponent={EntityFieldItem} headers={headers} getKey={x => "Name" in x ? x.Name : x.field.toString()} onSort={onSort} reload={requestDataCallback} valueSearch={valueSearch} />
            </div>
        </div>
    );
};

export const ClientFieldConfiguration = () => <EntityFieldConfiguration entity={Entities.Client} />;
export const InvoiceFieldConfiguration = () => <EntityFieldConfiguration entity={Entities.Invoice} />;
export const PaymentFieldConfiguration = () => <EntityFieldConfiguration entity={Entities.Payment} />;