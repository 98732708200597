import React, { ChangeEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { FilterCollection } from "../../services/FilterService";
import AdvancedFilters from "../shared/components/AdvancedFilters";
import { TranslationService } from "../../services/TranslationService";
import Table, { TableHeader } from "../shared/Table";
import TabPosition from "../shared/TabPosition";
import ListFilters from "./components/TaskListFilters";
import TaskMassiveChanges from "./components/TaskMassiveChanges";
import { TaskTableItemWithCheckbox } from "./TaskTableItem";
import TaskTableProvider from "./TaskTableProvider";
import TaskService from "../../services/TaskSerivce";
import { TaskListRequest } from "./entities/TaskListRequest";
import { Switch } from "../../utils/Utils";
import CompanyService from "../../services/CompanyService";
import { parseIntOrDefault } from "../../utils/ParseUtils";
import { FixToTop } from "../shared/FixToTop";

const TaskList: React.FC = () => {
    const [selectedTasks, setSelectedTasks] = useState<{ taskIds: number[], "all": boolean }>({ taskIds: [], all: false });
    const { state, search } = useLocation<{ prevRequest?: TaskListRequest } | undefined>();
    const urlParams = new URLSearchParams(search);
    const filterQS = (state?.prevRequest ? state.prevRequest.filter : urlParams.get("filter")) ?? "";
    const userIdQS = (state?.prevRequest ? state.prevRequest.uid : urlParams.get("uid")) ?? "";
    const statusIdQS = (state?.prevRequest ? state.prevRequest.status?.toString() : urlParams.get("statusId")) ?? "";
    const { translate } = TranslationService;
    const prevRequest = Switch(
        [Boolean(state?.prevRequest), state?.prevRequest],
        [Boolean(urlParams.get("filter")), () => { const req = new TaskListRequest(userIdQS, parseIntOrDefault(statusIdQS, 0), CompanyService.getAdditionalDefinitions()); req.filter = urlParams.get("filter") ?? ""; return req; }],
    );

    const clear = () => {
        setSelectedTasks({ taskIds: [], all: false });
    };

    const getPagedTaskIds = () => {
        const taskIdList: number[] = [];
        const checkboxes = document.getElementsByClassName("checkbox-task");
        for (let index = 0; index < checkboxes.length; index++) {
            const element = checkboxes[index] as HTMLInputElement;
            const taskId = element.dataset["taskselected"];
            if (taskId) { taskIdList.push(parseInt(taskId)); }
        }
        return taskIdList;
    };

    const ToggleAll = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = (event.target as HTMLInputElement).checked;
        selectedTasks.all = checked;
        selectedTasks.taskIds = checked ? getPagedTaskIds() : [];
        setSelectedTasks({ ...selectedTasks });
    };

    const ToggleOne = (taskId: number, checked: boolean) => {
        let taskIds: number[] = [];
        if (checked) {
            taskIds = [...selectedTasks.taskIds, taskId];
        }
        else {
            taskIds = selectedTasks.taskIds.filter(x => x !== taskId);
        }
        setSelectedTasks({ ...selectedTasks, taskIds, all: false });
    };

    const tableHeaders: TableHeader[] = [
        new TableHeader("person", translate.Client, undefined, undefined, "w-200px"),
        new TableHeader("user", translate.Responsible, undefined, undefined, "w-200px"),
        new TableHeader("dueDate", translate.ExpirationDay, undefined, undefined, "w-150px"),
        new TableHeader("description", translate.Description),
        new TableHeader("status", translate.Status, undefined, undefined, "w-120px"),
        new TableHeader("Star", "", false, false, "w-40px"),
    ];

    const showCheckbox = TaskService.canCheckTask();

    if (showCheckbox) {
        tableHeaders.unshift(new TableHeader("Checkbox", () => <input type="checkbox" onChange={ToggleAll} checked={selectedTasks.all} />, false, false, "w-30px"));
    }

    return (
        <TabPosition module="task">
            <div className="container-fluid padding">
                <div className="card mh-100 p-0">
                    <TaskTableProvider userId={userIdQS === "" ? null : userIdQS} statusId={statusIdQS === "" ? null : parseInt(statusIdQS)} request={prevRequest}>
                        <FixToTop>
                            <ListFilters userId={userIdQS === "" ? null : userIdQS} statusId={statusIdQS === "" ? null : parseInt(statusIdQS)} />
                            <div className="pt-0 bg-white">
                                <AdvancedFilters page={FilterCollection.Task} defaultValue={filterQS} />
                            </div>
                            <TaskMassiveChanges selectedTasks={selectedTasks} clear={clear} />
                        </FixToTop>
                        <div className="p-4 pt-3">
                            <Table emptyMessage={translate.NoTasksFound}
                                stickyHeader={true}
                                headers={tableHeaders} item={TaskTableItemWithCheckbox(showCheckbox ?
                                    { onChange: ToggleOne, selectedTaskIds: selectedTasks.taskIds }
                                    : undefined)} />
                        </div>
                    </TaskTableProvider>
                </div>
            </div>
        </TabPosition>
    );
};

export default TaskList;