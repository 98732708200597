import ClientTableProvider from "./providers/ClientTableProvider";
import { TableHeader, default as Table } from "../shared/Table";
import TabPosition from "../shared/TabPosition";
import ClientTableItem from "./components/ClientTableItem";
import FilterContainer from "./components/FilterContainer";
import ClientTableTotal from "./components/ClientTableTotal";
import { useContext } from "react";
import LanguageContext from "../shared/LanguageContext";
import { DeleteAllClients } from "./components/DeleteAllClients";
import { useLocation } from "react-router-dom";
import { ClientListRequest } from "./entities/ClientListRequest";
import { Switch } from "../../utils/Utils";
import CompanyService from "../../services/CompanyService";
import { FixToTop } from "../shared/FixToTop";

const ClientList: React.FC = () => {
    const { translate } = useContext(LanguageContext);
    const { state, search } = useLocation<{ prevRequest?: ClientListRequest } | undefined>();
    const urlParams = new URLSearchParams(search);
    const prevRequest = Switch(
        [Boolean(state?.prevRequest), state?.prevRequest],
        [Boolean(urlParams.get("filter")), () => { const req = new ClientListRequest(); req.filter = urlParams.get("filter"); return req; }],
    );

    const tableHead: TableHeader[] = [
        new TableHeader("name", translate("", "Client"), false, true),
        new TableHeader("cant", translate("", "Invoices"), true, true),
        new TableHeader("amount", translate("", "Amount"), true, true),
        //new TableHeader("amountDue", translate("", "Due"), true, false, "w-150px"),
        //new TableHeader("amountNotDue", translate("", "NotDue"), true, false, "w-150px"),
        new TableHeader("due", "", false, false),
        new TableHeader("lastlog", translate("", "LastActivity"), false, true),
    ];
    if (!CompanyService.getCompanyAuth()?.HidePriority) {
        tableHead.push(new TableHeader("rank", translate("", "Priority"), true, true, "", translate("", "PriorityTip")));
    }

    return (
        <TabPosition module="client">
            <div className="container-fluid padding">
                <div className="card mh-100 p-0">
                    <ClientTableProvider prevRequest={prevRequest} >
                        <FixToTop>
                            <FilterContainer showActions />
                        </FixToTop>
                        <div className="p-4">
                            <Table headers={tableHead} item={ClientTableItem} stickyHeader={true}>
                                <ClientTableTotal />
                            </Table>
                        </div>
                        <DeleteAllClients />
                    </ClientTableProvider>
                </div>
            </div>
        </TabPosition>
    );
};

export default ClientList;
