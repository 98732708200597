import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import { DashboardTaskResponse } from "../../../entities/company/Dashboard/DashboardTaskResponse";
import CompanyService from "../../../services/CompanyService";
import DashboardService from "../../../services/DashboardService";
import { FilterCollection } from "../../../services/FilterService";
import { formatIntizaDate } from "../../../utils/FormatUtils";
import Loading from "../../shared/components/Loading";
import { TranslationService } from "../../../services/TranslationService";
import DashboardHeader from "./DashboardHeader";
import PriorityService from "../../../services/PriorityService";

const OverdueTaskChart = ({ dashboard, dashboardFilters }: { dashboard: DashboardEntity, dashboardFilters: FilterRequest[] }) => {
    const history = useHistory();
    const { currentLanguage } = TranslationService;
    const { translate } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<DashboardTaskResponse>();
    const [userId, setUserId] = useState<string>(CompanyService.getUserid());

    const requestData = async () => {
        setLoading(true);
        const result = await DashboardService.calculate(dashboard.DashboardID, dashboardFilters, userId);
        setLoading(false);
        if (result instanceof Error) {
            setError(true);
            return;
        }
        const dashboardResult = result as DashboardTaskResponse;
        setResponse(dashboardResult);
    };

    const userChanged = (userId: string) => {
        setUserId(userId);
    };

    const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters, userId]);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const totalPriorities = CompanyService.getTotalPriorities()!;

    const rankings = [];
    for (let i = 0; i < totalPriorities; i++) {
        rankings.push(i);
    }

    const gotoTask = (priority: number | null, period: number | null) => {
        if (priority) {
            dashboardFilters.push({
                field: "-14",
                operator: 0,
                value: priority.toString()
            });
        }
        if (period !== null) {
            const from = new Date();
            const to = new Date();
            switch (period) {
                case 0:
                    from.setDate(new Date().getDate() - 3);
                    break;
                case 4:
                    from.setDate(new Date().getDate() - 7);
                    to.setDate(new Date().getDate() - 4);
                    break;
                case 8:
                    from.setDate(new Date().getDate() - 15);
                    to.setDate(new Date().getDate() - 8);
                    break;
                case 16:
                    from.setFullYear(1972);
                    to.setDate(new Date().getDate() - 16);
                    break;
            }

            dashboardFilters.push({
                field: "-4",
                operator: 0,
                value: formatIntizaDate(from) + "-" + formatIntizaDate(to)
            });
        }
        const qs = encodeURIComponent(DashboardService.GetQueryFilter(dashboardFilters, FilterCollection.Task) || "");
        history.push(`/${currentLanguage}/task?filter=${qs}&uid=${userId}&statusId=3`);
    };

    if (loading) {
        return (<Loading height={415} />);
    }

    if (error || !response) {
        return <></>;
    }

    return (
        <div className="card">
            <div className="card-body">
                <DashboardHeader title={translate.OverdueTasks} tooltip={translate.OverdueTasksTip} onUserSelected={userChanged} userId={userId} viewAll={() => gotoTask(null, null)} />
                <div className="table-responsive">
                    <table className="table tablePriority">
                        <thead>
                            <tr>
                                <th>{translate.Priority}</th>
                                <th className="text-end">0 - 3 {translate.Days}</th>
                                <th className="text-end">4 - 7 {translate.Days}</th>
                                <th className="text-end">8 - 15 {translate.Days}</th>
                                <th className="text-end">{">"} 15 {translate.Days}</th>
                                <th className="text-end"><strong>{translate.Total}</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rankings.map((x, index) => {
                                const rankingIndex = PriorityService.getRankingIndex(index + 1) as number;
                                return (
                                    <tr key={index}>
                                        <td className="fw-normal">
                                            <span className={"priority-" + rankingIndex}>{index + 1}</span>&nbsp;{PriorityService.getPriorityRankText(index + 1)}
                                        </td>
                                        <td className="fw-normal text-end">
                                            <button className="btn py-0" onClick={() => { gotoTask(index + 1, 0); }}>
                                                {response.list.find(x => x.Ranking === index + 1 && x.Period === 0)?.Cant ?? 0}
                                            </button>
                                        </td>
                                        <td className="fw-normal text-end">
                                            <button className="btn py-0" onClick={() => { gotoTask(index + 1, 4); }}>
                                                {response.list.find(x => x.Ranking === index + 1 && x.Period === 4)?.Cant ?? 0}
                                            </button>
                                        </td>
                                        <td className="fw-normal text-end">
                                            <button className="btn py-0" onClick={() => { gotoTask(index + 1, 8); }}>
                                                {response.list.find(x => x.Ranking === index + 1 && x.Period === 8)?.Cant ?? 0}
                                            </button>
                                        </td>
                                        <td className="fw-normal text-end">
                                            <button className="btn py-0" onClick={() => { gotoTask(index + 1, 16); }}>
                                                {response.list.find(x => x.Ranking === index + 1 && x.Period === 16)?.Cant ?? 0}
                                            </button>
                                        </td>
                                        <td className="fw-normal text-end">
                                            <button className="btn py-0" onClick={() => { gotoTask(index + 1, null); }}>
                                                {response.list.filter(x => x.Ranking === index + 1).reduce((x, y) => x + y.Cant, 0)}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td><strong>{translate.Total}</strong></td>
                                <td className="text-end">
                                    <button className="btn" onClick={() => { gotoTask(null, 0); }}>
                                        <strong>
                                            {response.list.filter(x => x.Period === 0).reduce((x, y) => x + y.Cant, 0)}
                                        </strong>
                                    </button>
                                </td>
                                <td className="text-end">
                                    <button className="btn" onClick={() => { gotoTask(null, 4); }}>
                                        <strong>
                                            {response.list.filter(x => x.Period === 4).reduce((x, y) => x + y.Cant, 0)}
                                        </strong>
                                    </button>
                                </td>
                                <td className="text-end">
                                    <button className="btn" onClick={() => { gotoTask(null, 8); }}>
                                        <strong>
                                            {response.list.filter(x => x.Period === 8).reduce((x, y) => x + y.Cant, 0)}
                                        </strong>
                                    </button>
                                </td>
                                <td className="text-end">
                                    <button className="btn" onClick={() => { gotoTask(null, 16); }}>
                                        <strong>
                                            {response.list.filter(x => x.Period === 16).reduce((x, y) => x + y.Cant, 0)}
                                        </strong>
                                    </button>
                                </td>
                                <td className="text-end">
                                    <button className="btn" onClick={() => { gotoTask(null, null); }}>
                                        <strong>
                                            {response.list.reduce((x, y) => x + y.Cant, 0)}
                                        </strong>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default OverdueTaskChart;