import React, { useEffect, useMemo, useState } from "react";
import ActionService from "../../../services/ActionService";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { useEndpointData } from "../../../utils/TableUtils";
import { ActionListResponse } from "../entities/ActionListResponse";

export class ActionTableContextValues extends TableContextValues<ActionListResponse> {
    paused: boolean = false;
    setPaused = (val: boolean) => { };
}

const ActionTableProvider = (props: React.PropsWithChildren<{}>) => {
    const value = useEndpointData(ActionService.list) as ActionTableContextValues;
    const [filterKey, setFilterKey] = useState<string>();
    const [paused, setPaused] = useState(value.response instanceof Error ? false : Boolean(value.response?.disabled));
    value.paused = paused;
    value.setPaused = setPaused;

    const dataFiltered: ActionListResponse | undefined = useMemo(() => {
        if (value.response) {
            let newList = value.response.list;
            if (filterKey) {
                newList = value.response?.list.filter(d => d.name?.toLowerCase().includes(filterKey));
            }
            return { ...value.response, list: newList };
        }
    }, [filterKey, value.response])


    const applySearch = (keyword: string) => {
        setFilterKey(keyword.toLowerCase())
    };

    value.applySearch = applySearch;
    value.response = dataFiltered;

    useEffect(() => {
        setPaused(x => value.response instanceof Error ? x : Boolean(value.response?.disabled));
    }, [value.response]);

    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}

export default ActionTableProvider;

