import { useEffect, useMemo, useState } from "react";
import { DashboardFilterEntity } from "../../../entities/company/Dashboard/DashboardFilterEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import { DashboardPanelEntity } from "../../../entities/company/DashboardPanel/DashboardPanelEntity";
import BarChart from "./BarChart";
import CardChart from "./CardChart";
import DebtChart from "./DebtChart";
import InvoiceStatusChart from "./InvoiceStatusChart";
import LineChart from "./LineChart";
import OverdueTaskChart from "./OverdueTaskChart";
import PieChart from "./PieChart";
import PriorityChart from "./PriorityChart";
import TableChart from "./TableChart";
import AgingChart from "./AgingChart";
import { NotNullMap } from "../../../utils/Utils";
import Highcharts from "highcharts";
import { TranslationService } from "../../../services/TranslationService";
import CompanyService from "../../../services/CompanyService";
import CalendarTask from "./CalendarTask";

const DashboardPanel = ({ dashboardPanel }: { dashboardPanel?: DashboardPanelEntity }) => {
    const [dashboardFilters, setDashboardFilters] = useState<DashboardFilterEntity[]>([]);
    Highcharts.setOptions({
        lang: {
            viewFullscreen: TranslationService.translate.HighchartsFullScreen,
            exitFullscreen: TranslationService.translate.HighChartsExitFullScreen,
            printChart: TranslationService.translate.HighchartsPrint,
            downloadPNG: TranslationService.translate.HighchartsDownloadPNG,
            downloadPDF: TranslationService.translate.HighchartsDownloadPDF
        }
    });

    useEffect(() => {
        setDashboardFilters(x => x.length === 0 ? x : []);
    }, [dashboardPanel]);

    const filterCallback = (dashboardFilter: DashboardFilterEntity) => {
        setDashboardFilters(dashboardFilters => {
            if (dashboardFilters.find(x => x.dashboard.DashboardID === dashboardFilter.dashboard.DashboardID)) {
                return dashboardFilters;
            }
            dashboardFilters.push(dashboardFilter);
            return ([...dashboardFilters]);
        });
    };

    const deleteFilter = (dashboardId: number) => {
        const index = dashboardFilters.findIndex(x => x.dashboard.DashboardID === dashboardId);
        dashboardFilters.splice(index, 1);
        setDashboardFilters([...dashboardFilters]);
    };

    const filters: FilterRequest[] = useMemo(() =>
        dashboardFilters.map(({ filterEntity, value, operator }) => ({ field: filterEntity, value: value, operator: parseInt(operator) })), [dashboardFilters]);

    if (!dashboardPanel) {
        return (<></>);
    }

    return (
        <section className="dashboard">
            <div className="container-fluid">
                {dashboardFilters.length > 0 &&
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="chipsRowFilter m-0">
                                {dashboardFilters.map(x => {
                                    return (
                                        <div key={x.dashboard.DashboardID} className="chipItem" >
                                            {NotNullMap(x.dashboard.Title, x => x + ": ")}{x.option} <i className="fa fa-times ms-2 pointer" onClick={() => deleteFilter(x.dashboard.DashboardID)}></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                }

                {dashboardPanel.Dashboards
                    .filter(x => !(CompanyService.getCompanyAuth()?.HidePriority && x.Type === 5))
                    .map(x => {
                        if (x.Type === 3) {
                            return (<DebtChart key={x.DashboardID} dashboard={x} dashboardFilters={filters}></DebtChart>);
                        }
                        return (

                            <div key={x.DashboardID}>
                                {x.Type === 0 && <PieChart dashboard={x} dashboardFilters={filters} filterCallback={filterCallback} ></PieChart>}
                                {x.Type === 1 && <BarChart dashboard={x} dashboardFilters={filters} filterCallback={filterCallback}></BarChart>}
                                {x.Type === 2 && <LineChart dashboard={x} dashboardFilters={filters} filterCallback={filterCallback}></LineChart>}
                                {x.Type === 4 && <InvoiceStatusChart dashboard={x} dashboardFilters={filters}></InvoiceStatusChart>}
                                {x.Type === 5 && <PriorityChart dashboard={x} dashboardFilters={filters}></PriorityChart>}
                                {x.Type === 6 && <OverdueTaskChart dashboard={x} dashboardFilters={filters}></OverdueTaskChart>}
                                {x.Type === 7 && <TableChart dashboard={x} dashboardFilters={filters}></TableChart>}
                                {x.Type === 8 && <CardChart dashboard={x} dashboardFilters={filters}></CardChart>}
                                {x.Type === 9 && <BarChart dashboard={x} dashboardFilters={filters} filterCallback={filterCallback}></BarChart>} {/* cashflow */}
                                {x.Type === 10 && <BarChart dashboard={x} dashboardFilters={filters} filterCallback={filterCallback}></BarChart>}
                                {x.Type === 13 && <AgingChart dashboard={x} dashboardFilters={filters} filterCallback={filterCallback}></AgingChart>}
                                {/* {x.Type === 42 && <CalendarTask />} */}
                            </div>
                        );
                    })
                    .insert(1, <CalendarTask />)}
            </div>
        </section >

    );
};

export default DashboardPanel;