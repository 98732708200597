import React, { useMemo, useState } from "react";
// import ImportTemplateService from "../../../../services/ImportTemplateService";
import Collapsable from "../../../shared/bootstrap/Collapsable";
// import ToastContext from "../../../shared/bootstrap/Toast";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
// import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from "../../../../services/TranslationService";
import { RequiredManager } from "../../../shared/RequieredManager";
import FieldList from "./Components/FieldList";
import { CompanyAuth } from "../../../../entities/login/CompanyAuth";
import { ToastService } from "../../../shared/bootstrap/Toast";
import ImportTemplateService from "../../../../services/ImportTemplateService";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";


export type ImportTemplateComponentProps = {
    data: ImportTemplateListResponse.Item | undefined,
    updateModel: UpdateModelFunc,
    model: Partial<ImportTemplateListResponse.Item>,
    setModel: React.Dispatch<React.SetStateAction<Partial<ImportTemplateListResponse.Item>>>,
    requiredManager: RequiredManager,
    requiredFieldIds: string[],
}

const ImportTemplateEditBase = ({ data, reload, requiredFieldIds, Header, AdvancedOptions = undefined, runNow = undefined }: {
    data?: ImportTemplateListResponse.Item, reload: () => void, requiredFieldIds: string[], Header: React.FC, AdvancedOptions?: React.FC<ImportTemplateComponentProps>,
    runNow?: () => void,
}) => {
    const { translate } = TranslationService;
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [model, setModel] = useState<Partial<ImportTemplateListResponse.Item>>(data ?? {});
    const [submiting, setSubmiting] = useState(false);
    const requiredManager = useMemo(() => new RequiredManager(), []);
    const entityId = useMemo(() => data?.entity, [data]);
    const fields: TemplateField[] = useMemo(() => entityId !== undefined ? ImportTemplateService.getTemplateFields(entityId) : [], [entityId]);

    const onSubmit = async () => {
        if (submiting) {
            return;
        }
        if (!requiredManager.validate()) {
            ToastService.showToast(translate.MissingRequiredFields);
            return;
        }
        const columns = model.Columns?.map(x => x.ix).filter(x => x);
        const columnsCount = columns?.length;
        const set = new Set(columns);
        if ([...set].length !== (columnsCount ?? 0)) {
            ToastService.showToast(TranslationService.translate.ImportTemplateConfigurationRepeatedColumns, undefined, "warning");
            return;
        }
        setSubmiting(true);
        const result = await ImportTemplateService.set(model as ImportTemplateListResponse.Item);
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        ToastService.showToast(translate.ImportTemplateSaved, undefined, "success");
        setSubmiting(false);
        reload();
        FloatingPanelService.hidePanel();
    };

    const updateModel: UpdateModelFunc = <T extends keyof ImportTemplateListResponse.Item,>(key: T) => (value: ImportTemplateListResponse.Item[T]) => {
        setModel(x => ({ ...x, [key]: value }));
    };

    const props: ImportTemplateComponentProps = {
        data,
        updateModel,
        model,
        setModel,
        requiredManager,
        requiredFieldIds,
    };

    return (
        <>
            <div className="floatingBody p-4">
                <Header />
                <FieldList
                    data={data}
                    updateModel={updateModel}
                    model={model}
                    setModel={setModel}
                    fields={fields}
                    requiredFieldIds={requiredFieldIds}
                    requiredManager={requiredManager}
                />
                {AdvancedOptions && <>
                    <button className="btn btn-link mb-2 d-block ps-0" onClick={() => setShowAdvanced(x => !x)}>{showAdvanced ? translate.HideAdvancedOptions : translate.ShowAdvancedOptions}</button>
                    <Collapsable show={showAdvanced}>
                        <AdvancedOptions   {...props} />
                    </Collapsable>
                </>}
            </div>
            <FloatingPanelFooter>
                {runNow && <button className='btn btn-secondary' onClick={runNow}>
                    {translate.RunNow}
                </button>}
                <button className='btn btn-primary' onClick={onSubmit}>
                    {translate.Save}
                    {submiting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

export function createFieldData(key: string, label: string): TemplateField {
    return {
        fieldId: key,
        key: key,
        label: () => <>{label}</>,
    };
}

export function additionalDefsToFieldData(additionalDefs: CompanyAuth.AdditionalDefinition[]) {
    return additionalDefs.map(x => (
        {
            fieldId: x.AdditionalDefinitionID.toString(),
            key: "additional-" + x.AdditionalDefinitionID.toString(),
            label: () => <>{x.Name}</>,
        }));
}

export const sheetColumns: readonly { text: string; value: number; }[] = Array.from({ length: 10 }, (_, i) => i + 9).flatMap(x => Array.from({ length: 26 }, (_, i) => i + 10).map(y => x.toString(36).replace("9", "").toUpperCase() + y.toString(36).toUpperCase())).map((x, i) => ({ text: x, value: i + 1 }));

export type TemplateField = { fieldId: string; key: string; label: () => JSX.Element; };
export type UpdateModelFunc = <T extends keyof ImportTemplateListResponse.Item>(key: T) => (value: ImportTemplateListResponse.Item[T]) => void;

export default ImportTemplateEditBase;