import { useCallback, useContext } from "react";
import { TranslationService } from "../../services/TranslationService";
import FloatingPanelService from "../shared/FloatingPanel";
import { WhatsappCompose } from "../client/components/WhatsappCompose";
import ClientService from "../../services/ClientService";
import TabPositionContext from "../shared/TabPositionContext";
import { QUICK_TABS } from "./InboxTabContent";
import { TableHeaderWithFieldId } from "../shared/Table";
import { formatDateDigits, formatTimeShort, isMoreThan24Hours } from "../../utils/FormatUtils";
import { InboxContext } from "./InboxTableProvider";
import { useHistory } from "react-router-dom";
import ActivityService from "../../services/ActivityService";
import { ToastService } from "../shared/bootstrap/Toast";
import { EmailEdit } from "../client/activity/components/EmailEdit";
import { restTimezone } from "../../utils/ParseUtils";

export const InboxTableTableColumn = ({ data, header }: { data: any, header: TableHeaderWithFieldId }) => {
    const { getCounterInbox } = useContext(TabPositionContext)
    const { request } = useContext(InboxContext)

    const getValue = () => data[header.fieldId];
    const value = getValue();
    const navigate = useHistory();

    const openNewWhatsappModal = useCallback(async () => {
        FloatingPanelService.hidePanel();
        const result = await ClientService.get(data.PersonId);
        if (result instanceof Error) {
            return;
        }
        const person = result.item
        person && FloatingPanelService.showPanel({
            title: TranslationService.translate.NewWhatsApp,
            children: <WhatsappCompose
                person={person}
                personId={data.PersonId}
                getCounterInbox={getCounterInbox} />,
            width: 800,
            height: 800,
            position: 'bottom-right'
        });
    }, [data.PersonId, getCounterInbox]);

    const editEmailModal = useCallback(async () => {
        const emailRequest = await ActivityService.get(data.ID);
        if (emailRequest instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        FloatingPanelService.showPanel({
            title: TranslationService.translate.EmailToClient,
            children: <EmailEdit email={emailRequest.item} />,
            width: 600,
            height: 569,
        });
    }, [data])

    const handleOpenModal = () => {
        switch (request.quickfilter) {
            case QUICK_TABS.WHATSAPP:
                openNewWhatsappModal()
                break;
            case QUICK_TABS.EMAIL:
                editEmailModal()
                break;
        }
    }

    const handleNavigateClient = async () => {
        const result = await ClientService.get(data.PersonId);
        if (result instanceof Error) {
            return;
        }
        const person = result.item
        await navigate.replace(`client/details?id=${person.PersonId.toString()}}`
            //    &dataID=${data.ID}&openModalDefault=${quickfilter === 8 ? 1 : quickfilter}`
        )
    }

    function decodeHTMLEntities(text: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        return doc.documentElement.textContent;
    }

    function truncateText(htmlText: string, maxLength: number) {
        const decodedText = decodeHTMLEntities(htmlText); // Decodificar las entidades HTML
        return (decodedText && decodedText?.length > maxLength) ? decodedText.substring(0, maxLength) + '...' : decodedText;
    }

    switch (header.fieldId) {
        case "date":
            const dateFormated = !isMoreThan24Hours(data?.date) ? formatTimeShort(restTimezone(new Date(data?.date))) : formatDateDigits(new Date(data?.date));
            return <td onClick={handleOpenModal}>{!data.Read ? <strong>{dateFormated}</strong> : dateFormated}</td>;
        case "personName":
            return <td onClick={handleNavigateClient}>{!data.Read ? <strong>{data.personName}</strong> : data.personName}</td >;
        case "Content":
            return <td onClick={handleOpenModal}>{!data.Read ? <strong>{data.Content}</strong> : data.Content}</td >;
        case "Subject":
            const content = truncateText(data.Content, 200)
            const dataContent = <><strong>{data.Subject}</strong> - {!data.Read ? <strong><span>{content}</span></strong> : <span>{content}</span>} </>;
            return <td onClick={handleOpenModal}>{dataContent}</td>;
        default:
            return <td onClick={handleOpenModal}>{!data.Read ? <strong>{value}</strong> : value}</td>
    }
};
