import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";
import TooltipComponent from "../../shared/TooltipComponent";

export const DashboardClientSales = (props: { averageAgingPerson: string | undefined, averageAging: string, averagePeriodPerson: string | undefined, averagePeriod: string, totalAmount: number | undefined, currencyId?: number }) => {
    const { translate } = TranslationService;
    const { averageAgingPerson, averageAging, averagePeriodPerson, averagePeriod, totalAmount, currencyId = CompanyService.getDefaultCurrencyId() } = props;
    return (
        <div className="dashboard__sales row">
            <div className="col-4 pe-0">
                <div className="dashboard__total card p-3">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p>{translate.TotalReceivables}</p>
                            <span></span>
                        </div>
                        <h2>{formatCurrency(totalAmount ?? 0, currencyId, formatInteger)}</h2>
                    </div>
                </div>
            </div>
            <div className="col-4 pe-0">
                <TooltipComponent title={translate.DebtAgingTip}>
                    <div className="dashboard__notExpired card p-3">
                        <div className="card-body px-2">
                            <div className="card-header mb-1">
                                <p>{translate.DebtAvgAging} <i className="ms-1 fal fa-question-circle"></i></p>
                                <span>{" (" + (averageAging) + ")"}</span>
                            </div>
                            <h2>{(averageAgingPerson) + " " + translate.Days}</h2>
                        </div>
                    </div>
                </TooltipComponent>
            </div>
            <div className="col-4">
                <TooltipComponent title={translate.AvaragePaymentsTip}>
                    <div className="dashboard__expired card p-3">
                        <div className="card-body px-2">
                            <div className="card-header mb-1">
                                <p>{translate.ReportAveragePayment} <i className="ms-1 fal fa-question-circle"></i></p>
                                <span>{" (" + (averagePeriod) + ")"}</span>
                            </div>
                            <h2>{(averagePeriodPerson) + " " + translate.Days}</h2>
                        </div>
                    </div>
                </TooltipComponent>
            </div>
        </div>
    );
}

export default DashboardClientSales;