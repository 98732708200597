import CompanyService from "../../../../services/CompanyService";
import { FilterCollection } from "../../../../services/FilterService";
import { PickByValue } from "../../../../utils/Utils";
import AdvancedFilters, { AdvancedFiltersContext } from "../../../shared/components/AdvancedFilters";
import Dropdown from "../../../shared/components/Dropdown";
import { CheckBoxEditor } from "../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from '../../../../services/TranslationService';
import Alarm from "./Components/Alarm";
import DateFormat from "./Components/DateFormat";
import Dictionaries from "./Components/Dictionaries";
import EditItem from "./Components/EditItem";
import ExcelOptions from "./Components/ExcelOptions";
import PrecedingProcesses from "./Components/PrecedingProcesses";
import SeparatorWithFixedColumns from "./Components/SeparatorWithFixedColumns";
import UploadTemplate from "./Components/UploadTemplate";
import ImportTemplateEditBase, { ImportTemplateComponentProps } from "./ImportTemplateEditBase";
import AdvancedFilterService from "../../../../services/AdvancedFilterService";
import { useContext, useEffect } from "react";

const ImportTemplateEditInvoiceNew = ({ data, reload, importsList = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse }) => {
    const { translate } = TranslationService;
    const requiredIds = ["contact", "createdDate", "referenceNumber", "amount"];
    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    useEffect(() => {
        advancedFiltersContext.setShow(true);
    }, [advancedFiltersContext]);

    const Header = () => {
        return (<div className="mb-3 mt-4">
            <h5 className="mb-4">{translate.ImportConfigurationOfNewInvoices} </h5>
            <details className="mt-3">
                <summary className="ps-1"><h6 style={{ display: "unset" }}>{translate.SeeTheRequirementsOfTheFileToImport}</h6></summary>
                {translate.RequirementsOfTheFileToImport.split("\n").map((x, i) => <div key={i} className="text-muted ps-3">{x}</div>)}
            </details>
        </div>);
    }

    const AdvancedOptions = ({ data, updateModel, model, setModel, }: ImportTemplateComponentProps) => {
        const updateNumberModel = <T extends keyof PickByValue<ImportTemplateListResponse.Item, number | undefined>,>(key: T) => (value: number) => {
            setModel(x => ({ ...x, [key]: value }));
        }
        const applyFilters = (filters: string[] | undefined) => setModel(x => ({ ...x, IOFilter: AdvancedFilterService.filterStringsToQueryString(filters) }));
        return (<div>
            <small className="text-muted mb-3">{translate.IfYouLeaveFormatBlank}</small>
            <DateFormat defaultValue={data?.DateFormat} updateModel={updateModel("DateFormat")} label={translate.IssueDateFormat} />
            <DateFormat defaultValue={data?.DueDateFormat} updateModel={updateModel("DueDateFormat")} label={translate.DueDateFormat} />
            <CheckBoxEditor message={translate.UpdateExistingInvoiceDataWhenSynchronizing} onChange={updateModel("UpdateIOs")} defaultValue={data?.UpdateIOs} />
            {CompanyService.getIsAdmin() &&
                <div className="py-2 mb-3" style={{ paddingLeft: 5, marginLeft: -5, backgroundColor: "#f5eebe" }}>
                    <CheckBoxEditor message={"Actualizar datos de facturas pagadas"} onChange={updateModel("IncludePaid")} defaultValue={data?.IncludePaid} />
                    <CheckBoxEditor message={"Procesar facturas con monto 0 en el archivo"} onChange={updateModel("processZeroIOs")} defaultValue={data?.processZeroIOs} />
                </div>
            }
            <EditItem label={translate.WhenInvoiceRepeated} ><Dropdown onChange={updateNumberModel("OnRepeat")} defaultValue={data?.OnRepeat} items={[{ value: 0, text: translate.DiscardFile }, { value: 1, text: translate.DiscardRepeatedInvoices }, { value: 2, text: translate.SumRepeatedInvoicesAmount }, { value: 3, text: translate.TakeFirst }, { value: 4, text: translate.TakeLast },]} /></EditItem>
            <UploadTemplate formatsAllowed=".xlsx,.csv" model={model} updateModel={updateModel} />
            <SeparatorWithFixedColumns updateModel={updateModel} data={data} model={model} />
            <ExcelOptions data={data} updateModel={updateModel} />
            <hr className="my-5" />
            <Dictionaries data={data} model={model} setModel={setModel} />
            {CompanyService.getSetting("partialiosync") && <><hr className="my-5" />
                <h5 className="mb-3">{translate.FilterInvoices}</h5><AdvancedFilters page={FilterCollection.Invoice} onFilterApply={applyFilters} defaultValue={data?.IOFilter} />
            </>}
            <hr className="my-5" />
            <PrecedingProcesses data={data} model={model} setModel={setModel} importsList={importsList} />
            <hr className="my-5" />
            <Alarm data={data} updateModel={updateModel} setModel={setModel} model={model} />
        </div>)
    }
    return (
        <ImportTemplateEditBase AdvancedOptions={AdvancedOptions} Header={Header} reload={reload} requiredFieldIds={requiredIds} data={data} />
    )
}

export default ImportTemplateEditInvoiceNew;