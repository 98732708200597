import intizaLogoPng from "../../assets/img/intizaLogoWhite.png";
import { Link } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";


const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer>
            <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-3">
                        <Link to={"/"} className="navbar-brand">
                            <img src={intizaLogoPng} alt="Intiza" />
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <ul style={{ display: 'flex' }}>
                            <li><a href="/">{TranslationService.translate.ContactSupport}</a></li>
                            <li><a href="/">{TranslationService.translate.Help}</a></li>
                            <li><a href="/">{TranslationService.translate.ContactInfo}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 d-flex justify-content-end">
                        <div className="rrss" style={{ display: 'flex' }}>
                            <a href="https://www.linkedin.com/company/intiza/" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>

                            <a href="https://x.com/IntizaSaaS" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 18 18">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="subfooter">
                        Copyright {year} | Intiza S.A. {TranslationService.translate.Rights}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;