import { useCallback } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { useEndpointData } from "../../../utils/TableUtils";
import { ReportReceivablesSummaryService } from "../../../services/ReportReceivablesSummaryService";
import { Cast } from "../../../utils/Utils";
import { MonthlyDataRow } from "../../../entities/reports/ReportReceivablesSummary/MonthlyDataResponse";
import { formatCurrency, formatDateMonthYearShort, formatIntizaDate } from "../../../utils/FormatUtils";
import { TableHeader } from "../../shared/Table";
import AdvancedFilterService, { Filter } from "../../../services/AdvancedFilterService";
import FilterService, { FilterEntity } from "../../../services/FilterService";
import { Link } from "react-router-dom";
import moment from "moment";

export const RrsMonthlyDetail = ({ filter }: { filter: string }) => {
    const endpoint = useCallback(() => ReportReceivablesSummaryService.getMonthlyData(filter), [filter]);
    const tableValue = useEndpointData(endpoint);
    if (tableValue.response) {
        const typeErased = Cast<typeof tableValue.response & { list: typeof tableValue.response.data }>(tableValue.response);
        typeErased.list = typeErased.data;
    }
    return (
        <>
            <div className="card mh-100 mb-2">
                <div className="row justify-content-end align-items-center">
                    <div className="col-4 d-flex justify-content-center">
                        <h5 className="h5">{TranslationService.translate.MonthlyDetail}</h5>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <button className="btn btn-tertiary" onClick={() => ReportReceivablesSummaryService.exportMonthlyData(filter)}>{TranslationService.translate.ExportToXls}</button >
                    </div>
                </div>
                <table className="table genericTable table-ellipsis mt-3 rrsTable">
                    <thead>
                        {getHeaders().map(x => <th key={x.key} className={x.numeric ? "text-end" : ""}>
                            {x.label()}
                        </th>)}
                    </thead>
                    <tbody>
                        {tableValue.response?.data.slice(0, 25).map(x => <MonthlyDetailItem data={x} />)}
                    </tbody>
                </table>
            </div>
        </>
    );
}

const MonthlyDetailItem = ({ data }: { data: MonthlyDataRow }) => {
    const definitions = FilterService.GetFiltersForEntities([FilterEntity.Payment, FilterEntity.Invoice]).flatMap(x => x.definitions);
    const date = new Date(data.date);
    const createFilter = (fieldId: string, value: string, operator?: number) => {
        const definition = definitions.find(x => x.Field === fieldId)!;
        const filter: Filter = {
            definition,
            kind: definition.Type,
            value,
            operator,
        };
        return filter;
    }
    const createdFilter = createFilter("-303", formatIntizaDate(date) + "-" + formatIntizaDate(moment(date).endOf("month").toDate()));
    const emmitedPastFilter = createFilter("-1023", formatIntizaDate(moment().add(-50, "years").toDate()) + "-" + formatIntizaDate(moment(date).add(-1, "months").endOf("month").toDate()));
    const emmitedThisMonthFilter = createFilter("-1023", formatIntizaDate(moment(date).startOf("month").toDate()) + "-" + formatIntizaDate(moment(date).endOf("month").toDate()));

    const paymentLink = "payment?filter=";
    const invoicesLink = "iolist?filter=";
    return (<tr>
        <td>{formatDateMonthYearShort(date)}</td>
        <td className="text-end">{formatCurrency(data.debt)}</td>
        <td className="text-end"><Link to={paymentLink + AdvancedFilterService.filtersToRequestString([createdFilter, emmitedPastFilter])}>{formatCurrency(data.payment)}</Link></td>
        <td className="text-end">{data.percCollectedObj}</td>
        <td className="text-end"><Link to={invoicesLink + AdvancedFilterService.filtersToRequestString([emmitedThisMonthFilter])}>{formatCurrency(data.newdebt)}</Link></td>
        <td className="text-end"><Link to={paymentLink + AdvancedFilterService.filtersToRequestString([createdFilter, emmitedThisMonthFilter])}>{formatCurrency(data.newpayment)}</Link></td>
        <td className="text-end">{data.newPercCollectedObj}</td>
        <td className="text-end">{formatCurrency(data.total)}</td>
    </tr>);
}


const getHeaders = () => [
    new TableHeader("date", "", false, false),
    new TableHeader("debtbegginging", TranslationService.translate.DebtAtBeginningOfTheMonth, true, false),
    new TableHeader("collections", TranslationService.translate.Collections, true, false),
    new TableHeader("collected", "% " + TranslationService.translate.Collected2, true, false),
    new TableHeader("monthsales", TranslationService.translate.MonthSales, true, false),
    new TableHeader("postcollections", TranslationService.translate.Collections, true, false),
    new TableHeader("postcollected", "% " + TranslationService.translate.Collected2, true, false),
    new TableHeader("debtend", TranslationService.translate.DebtAtEndOfTheMonth, true, false),
];