import { useCallback, useEffect, useState } from "react";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { DashboardFilterEntity } from "../../../entities/company/Dashboard/DashboardFilterEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import DashboardService from "../../../services/DashboardService";
import Loading from "../../shared/components/Loading";
import { TranslationService } from "../../../services/TranslationService";
import HighchartChart from "./HighchartChart";
import ErrorCatcher from "../../shared/ErrorCatcher";

const BarChart = ({ dashboard, dashboardFilters, filterCallback }: { dashboard: DashboardEntity, dashboardFilters: FilterRequest[], filterCallback: (dashboardFilterEntity: DashboardFilterEntity) => void }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [response, setResponse] = useState<Highcharts.Options>();
	const [valuesEnabled, setValuesEnabled] = useState(false);

	const requestData = async () => {
		setLoading(true);
		const result = await DashboardService.calculate(dashboard.DashboardID, dashboardFilters);
		setLoading(false);
		if (result instanceof Error) {
			setError(true);
			return;
		}
		setError(false);
		const chart = result as { optionextend: Highcharts.Options };
		if (chart.optionextend.colors === null) {
			chart.optionextend.colors = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];
		}
		chart.optionextend.exporting?.buttons?.contextButton?.menuItems?.push("separator");
		const buttons = chart.optionextend.exporting?.buttons?.contextButton?.menuItems;
		const button = { text: TranslationService.translate.ShowHideValues, onclick: () => setValuesEnabled(prev => !prev) };
		(buttons as any).push(button);
		console.log(chart.optionextend);
		setResponse({ ...chart.optionextend });
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters]);

	useEffect(() => {
		requestDataCallback();
	}, [requestDataCallback]);

	if (loading) {
		return (<Loading height={400} />);
	}

	if (error || !response) {
		return (<></>);
	}

	return (
		<div className="card">
			<div className="card-body">
				<ErrorCatcher onError={() => setError(true)}>
					<HighchartChart
						dashboard={dashboard}
						options={response}
						dashboardFilters={dashboardFilters}
						filterCallback={filterCallback}
						valuesEnabled={valuesEnabled}
					/>
				</ErrorCatcher>
			</div>
		</div>
	);
};
export default BarChart;

