import { useState } from "react";

type Item = {
    title: string,
    component: () => JSX.Element,
    hash?: string,
}
const VerticalMenu = ({ items }: { items: Item[] }) => {
    const [index, setIndex] = useState(() => {
        const hashNoHash = window.location.hash.substring(1);
        return Math.max(0, items.findIndex(x => x.hash === hashNoHash));
    });
    return (
        <div className="d-flex">
            <nav className="nav-sidebar" style={{ width: 320 }}>
                <ul className="list-unstyled nav flex-column" role="tablist">
                    {items.map((x, i) => {
                        const titleSimple = x.title.replaceAll(" ", "");
                        return (<li className="nav-item" role="presentation" key={titleSimple}>
                            <button type="button" className={"btn btn-link nav-link text-start" + (i !== index ? "" : " active")}
                                id={"tab-" + titleSimple} data-bs-toggle="tab" data-bs-target={"#" + titleSimple + "-tab-pane"}
                                onClick={() => {
                                    window.location.hash = x.hash ?? "";
                                    setIndex(i);
                                }}
                                role="tab" aria-controls={titleSimple + "-tab-pane"}>
                                {x.title}
                            </button>
                        </li>);
                    })}
                </ul>
            </nav>
            <div className="tab-content p-5 w-100">
                {items.map((x, i) => {
                    const titleSimple = x.title.replaceAll(" ", "");
                    const Component = x.component;
                    return (<div key={titleSimple} className={"tab-pane fade" + (i !== index ? "" : " show active")}
                        id={titleSimple + "-tab-pane"} role="tabpanel" aria-labelledby={"tab-" + titleSimple} tabIndex={i}>
                        <Component />
                    </div>);
                })}
            </div>
        </div >
    );
};

export default VerticalMenu;