import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastService } from "../pages/shared/bootstrap/Toast";
import RequestService, { RequestPromise } from "../services/RequestService";
import { TranslationService } from "../services/TranslationService";

const defaultKey = "noEndpointKey";
export class RequestAborter {
    private prev: Record<string, undefined | Promise<unknown>> = {};
    next<T extends Promise<unknown>>(promise: T): T {
        const key = this.getKey(promise);
        this.abort(key);
        this.prev[key] = promise;
        return promise;
    }
    private abort(key: string) {
        const promise = this.prev[key];
        if (promise !== undefined) {
            abortRequest(promise);
        }
    }
    private freeRequests: Promise<unknown>[] = [];
    /* Requests without sequential requirements */
    push<T extends Promise<unknown>>(promise: T): T {
        promise.then(() => this.freeRequests = this.freeRequests.filter(x => x !== promise));
        this.freeRequests.push(promise);
        return promise;
    }
    abortAll() {
        Object.values(this.prev).filter(x => x !== undefined).forEach(x => abortRequest(x!));
        this.freeRequests.forEach(abortRequest);
        this.prev = {};
        this.freeRequests = [];
    }
    private getKey(promise: Promise<unknown> | undefined) {
        if (promise === undefined) {
            return defaultKey;
        }
        return "endpoint" in promise ? (promise as RequestPromise<unknown>).endpoint : defaultKey;
    }
}

export const useRequestAborter = () => {
    const requestAborter = useRef(new RequestAborter()).current;
    useEffect(() => {
        return () => requestAborter.abortAll();
    }, [requestAborter]);
    return requestAborter;
};

export function handleError<T>(request: Promise<T | Error>, onError?: ((error: ApiError) => string | void)): Promise<T> {
    const handledRequest = request.then(result => {
        if (isAbortError(result)) {
            throw new RequestError();
        }
        if (result instanceof Error) {
            onError && onError(getError(result));
            throw new RequestError();
        }
        return result;
    });
    assingRequestExtraProperties(request, handledRequest);
    return handledRequest;
}

export function handleErrorWithToast<T>(request: Promise<T | Error>, errorMessage?: string | ((error: ApiError) => string | void)): Promise<T> {
    const onError = (error: ApiError) => {
        const message = errorMessage === undefined ? undefined :
            typeof errorMessage === "string" ? errorMessage : errorMessage(error);
        ToastService.showToast(message ?? TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
    };
    return handleError(request, onError);
}

export function assingRequestExtraProperties(from: Promise<unknown>, to: Promise<unknown>) {
    const extraPropertiesKeys: (keyof RequestPromise<unknown>)[] = ["abortController", "endpoint"];
    const toReq = to as any;
    const fromReq = from as RequestPromise<unknown>;
    extraPropertiesKeys.forEach(key => {
        if (key in fromReq) {
            toReq[key] = fromReq[key];
        }
    });
}

export function isAbortError(error: unknown) {
    return axios.isCancel(error);
}

export function abortRequest(request: Promise<unknown>) {
    if ("abortController" in request) {
        const abortable = request as RequestPromise<unknown>;
        abortable.abortController.abort();
    } else {
        console.warn("Couldn't abort request because _abortController_ is missing.");
    }
}

export function lastRequestCleanup() {
    const abortController = RequestService.currentAbortController;
    return () => abortController.abort();
}

export async function endpointRequest<TResponse>(
    endpoint: () => Promise<TResponse | AxiosError<TResponse, any> | Error>,
    setData: (data: TResponse) => void,
    setLoading?: (value: boolean) => void,
    setError?: (value: boolean) => void,
    requestAborter?: RequestAborter,) {
    setLoading && setLoading(true);
    const request = requestAborter ? requestAborter.next(endpoint()) : endpoint();
    const result = await request!;
    if (result instanceof Error && isAbortError(result)) {
        return undefined;
    }
    if (result instanceof Error) {
        setError && setError(true);
        setLoading && setLoading(false);
        return undefined;
    }
    setData(result);
    setLoading && setLoading(false);
    return result;
}

export type ApiError = { status: number, message?: string, fullError: AxiosError };
export function getError(error: Error): ApiError {
    const axisError = error as AxiosError<{ Message: string }>;
    return { status: axisError.response!.status, message: axisError.response?.data.Message, fullError: axisError };
}

export function mapIfSuccess<T, U>(val: T | Error, map: (value: T) => U) {
    if (val instanceof Error) {
        return val;
    }
    return map(val);
}

type Datasource<T> =
    {
        reload: () => void,
    } & ({
        value: T,
        isError: false,
        isLoading: false,
    } | {
        value?: undefined,
        isError: true,
        isLoading: false,
    } | {
        value?: undefined,
        isError: false,
        isLoading: true,
    });

export function useDatasource<TFun extends (...args: any) => Promise<any | AxiosError<any, any> | Error>>(
    endpoint: TFun,
    params: Parameters<TFun>,
    options: {
        setIsLoading?: (value: boolean) => void,
        setIsError?: (value: boolean) => void,
    } = {}) {
    type TResponse = Exclude<Awaited<ReturnType<TFun>>, Error>;
    const [datasource, setDatasource] = useState<Datasource<TResponse>>({
        isLoading: true,
        isError: false,
        reload: () => { },
    });
    const { setIsLoading, setIsError } = options;
    const reload = useCallback(async () => {
        setIsLoading && setIsLoading(true);
        setIsError && setIsError(false);
        setDatasource({
            isLoading: true,
            isError: false,
            reload,
        });
        const result = await endpoint(...Object.values(params));
        if (result instanceof Error) {
            setIsError && setIsError(true);
            setDatasource({
                isLoading: false,
                isError: true,
                reload,
            });
        } else {
            setIsLoading && setIsLoading(false);
            setDatasource({
                isLoading: false,
                isError: false,
                value: result,
                reload,
            });
        }
        setIsLoading && setIsLoading(false);
        // SAFETY: It does not matter if the array *params* is different every frame, 
        // what matters is if the elements of *params* change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, setIsError, setIsLoading, ...params,]);
    useEffect(() => {
        reload();
    }, [reload]);
    return datasource;
}


export class RequestError extends Error {
    constructor(message?: string) {
        // 'Error' breaks prototype chain here
        super(message);

        // restore prototype chain   
        const actualProto = new.target.prototype;
        Object.setPrototypeOf && Object.setPrototypeOf(this, actualProto);
    }
}

export class RequestBuilder<T> {
    constructor(private promise: Promise<Error | T>) {
    }
    onError(fun: (e: Error) => void) {
        return new RequestBuilder(this.promise.catch(fun) as Promise<Error | T>);
    }
}