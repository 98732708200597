import { useMemo, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import Dropdown from "../../shared/components/Dropdown";
import { CheckBoxEditor } from "../../shared/components/Editors";
import SwitchButton from "../../shared/components/SwitchButton";
import languages from "../../shared/entities/Languages";
import { EmailComposeModel, EmailTabProps } from "./EmailCompose";
import { EmailInvoiceListType } from "../../../services/ActivityService";
import FilterService, { FilterCollection } from "../../../services/FilterService";
import AdvancedFilters, { AdvancedFiltersButton } from "../../shared/components/AdvancedFilters";
import CompanyService, { Entities } from "../../../services/CompanyService";
import { v4 } from "uuid";
import InvoiceService, { InvoiceFields } from "../../../services/InvoiceService";
import { EntityField } from "../../shared/entities/EntityField";

function getOrderBy(model: Partial<EmailComposeModel>, index: number) {
    return model?.item?.[(("orderby" + index) as "orderby1")];
}
function getOrderByDesc(model: Partial<EmailComposeModel>, index: number) {
    return model?.item?.[(("orderbyorderdesc" + index) as "orderbyorderdesc1")];
}
function getDefaultSortByItems(model: Partial<EmailComposeModel>) {
    const items = Array.from({ length: 3 }).map((x, i) => ({
        orderBy: getOrderBy(model, i),
        orderbyorderdesc: getOrderByDesc(model, i),
        _key: v4(),
    })).filter(x => x.orderBy);
    if (items.length === 0) {
        items.push({ orderBy: undefined, orderbyorderdesc: "off", _key: v4() });
    }
    return items;
}
export const EmailTabInvoice = ({ model, setModel, getFeature }: EmailTabProps) => {
    const [sortByItems, setSortByItems] = useState(getDefaultSortByItems(model));
    const orderByFeatureName = "orderby0";
    const setEmail = <TKey extends keyof EmailComposeModel["item"],>(key: TKey) =>
        (value: EmailComposeModel["item"][TKey]) =>
            setModel(model => {
                const newModel = { ...model };
                if (!newModel.item) {
                    newModel.item = {} as Partial<EmailComposeModel>["item"];
                }
                if (newModel.item) {
                    newModel.item[key] = value;
                }
                return newModel;
            });
    const invoiceListModeOptions: { value: "0" | "1", text: string }[] = useMemo(() => [
        { value: "0", text: TranslationService.translate.EmailOnBody },
        { value: "1", text: TranslationService.translate.EmailAsAttachment }
    ], []);
    const invoiceListTypeOptions = useMemo(() => [
        { value: EmailInvoiceListType.All, text: TranslationService.translate.AllInvoices },
        { value: EmailInvoiceListType.Pending, text: TranslationService.translate.AllPending },
        { value: EmailInvoiceListType.Due, text: TranslationService.translate.OnlyDues },
        { value: EmailInvoiceListType.Claimables, text: TranslationService.translate.OnlyClaimable },
        { value: EmailInvoiceListType.DueClaimables, text: TranslationService.translate.OnlyDuesClaimable },
    ], []);
    const invoiceReportExportOptions = useMemo(() => CompanyService.getReportExports()
        .filter(x => x.Report === Entities.Invoice && x.Public)
        .map(x => ({ value: x.ReportExportID, text: x.Name ?? TranslationService.translate.Default })), []);
    const applyAdvancedFilter = (filters: string[] | undefined) => {
        setModel(model => ({ ...model, filter: FilterService.GetExtraFiltersRequestString(filters) }));
    };

    const groupByFields = useMemo(() => [
        ...CompanyService.getAdditionalDefinitions()
            .filter(x => !x.ExcludeInFilters && x.Entity === Entities.Invoice)
            .map(x => new EntityField(x))
            .map(x => ({ value: x.id, text: x.title ?? "" })),
        ...(CompanyService.getGroupName() ? [{ value: -1007, text: CompanyService.getGroupName() }] : []),
        { value: InvoiceFields.amount, text: TranslationService.translate.Amount },
        { value: InvoiceFields.pending, text: TranslationService.translate.PendingAmount },
        { value: InvoiceFields.emittedDate, text: TranslationService.translate.IssueDate },
        { value: InvoiceFields.dueDate, text: TranslationService.translate.DueDate },
        { value: InvoiceFields.currency, text: TranslationService.translate.Currency },
        { value: InvoiceFields.iostatus, text: TranslationService.translate.InvoiceStatus }]
        .sort((x, y) => x.text.localeCompare(y.text)), []);

    const orderByFields = useMemo(() => [
        ...groupByFields,
        { value: InvoiceFields.referenceNumber, text: InvoiceService.getReferenceNumberName() },
    ], [groupByFields]);

    const deleteSortBy = (index: number) => {
        if (index === 0) {
            getFeature(orderByFeatureName).setFeatureValue("orderby0")(model?.item?.orderby1);
            getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc0")(model?.item?.orderbyorderdesc1 ?? "off");
        }
        if (index <= 1) {
            getFeature(orderByFeatureName).setFeatureValue("orderby1")(model?.item?.orderby2);
            getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc1")(model?.item?.orderbyorderdesc2 ?? "off");
        }
        getFeature(orderByFeatureName).setFeatureValue("orderby2")(undefined);
        getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc2")("off");
        setSortByItems(x => { x.splice(index, 1); return [...x]; });
    };
    return <>
        <div className="tabBody bills">
            <div className="card pb-3 mb-5">
                <SwitchButton label={TranslationService.translate.IncludeInvoices} onChange={x => { getFeature("includeinvoices").toggleFeature(x); getFeature("includeinvoices").setFeatureValue("includeinvoices")(x); }} defaultValue={getFeature("includeinvoices").isOn} />
                {getFeature("includeinvoices").isOn && <>
                    <div className="row mb-3 g-0 align-items-center justify-content-between">
                        <div className="col-3">
                        </div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col">
                                    <Dropdown onChange={getFeature("includeinvoices").setFeatureValue("quickfilter")} items={invoiceListTypeOptions} value={model.item?.quickfilter ?? EmailInvoiceListType.Pending} readonly={!getFeature("includeinvoices").isOn} />
                                </div>
                                <div className="col">
                                    <Dropdown onChange={getFeature("includeinvoices").setFeatureValue("listmode")} items={invoiceListModeOptions} defaultValue={model.item?.listmode} readonly={!getFeature("includeinvoices").isOn} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 g-0 align-items-start justify-content-between">
                        <div className="col-3">
                            <label className="form-check-label ms-5" htmlFor="flexSwitchCheckDefault">{TranslationService.translate.SpecialFilters} <br></br><i>({TranslationService.translate.Optional.toLowerCase()})</i></label>
                        </div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col">
                                    <AdvancedFiltersButton/>
                                    <AdvancedFilters defaultValue={model.item?.filter ?? undefined} page={FilterCollection.Client} onFilterApply={applyAdvancedFilter} />
                                    {/* <Dropdown onChange={() => { }} items={[{ value: '[Facturas] ', text: TranslationService.translate.InvoiceList }]} optionLabel={TranslationService.translate.SelectTemplate} readonly={!getFeature("includeinvoices").isOn} /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3 g-0 align-items-center justify-content-between">
                        <div className="col-3">
                            <label className="form-check-label ms-5" htmlFor="flexSwitchCheckDefault">{TranslationService.translate.Language}</label>
                        </div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col">
                                    <Dropdown onChange={setEmail("language")} items={languages} readonly={!getFeature("includeinvoices").isOn} defaultValue={model.item?.language} />
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row mb-3 mt-4 g-0 align-items-start justify-content-between">
                        <div className="col-3">
                            <SwitchButton label={TranslationService.translate.Templates} defaultValue={getFeature("reportexport").isOn} onChange={x => setTimeout(() => getFeature("reportexport").toggleFeature(x), 5)} disabled={!getFeature("includeinvoices").isOn} />
                        </div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col">
                                    <Dropdown onChange={getFeature("reportexport").setFeatureValue("reportexport")} items={invoiceReportExportOptions} optionLabel={TranslationService.translate.SelectTemplate} readonly={!getFeature("reportexport").isOn || !getFeature("includeinvoices").isOn} defaultValue={model.item?.reportexport} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {CompanyService.getSetting("alloweditgroupmailby") && <div className="row mb-4 g-0 align-items-start justify-content-between">
                        <div className="col-3">
                            <SwitchButton label={TranslationService.translate.GroupWithoutBy} onChange={x => setTimeout(() => getFeature("mail_groupby").toggleFeature(x), 5)} defaultValue={getFeature("mail_groupby").isOn} disabled={!getFeature("includeinvoices").isOn} />
                        </div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col">
                                    <Dropdown onChange={getFeature("mail_groupby").setFeatureValue("mail_groupby")} items={groupByFields} optionLabel={TranslationService.translate.Select} defaultValue={model.item?.mail_groupby} readonly={!getFeature("mail_groupby").isOn || !getFeature("includeinvoices").isOn} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    <hr></hr>
                    <br />
                    <div className="row mb-3 mt-4 g-0 align-items-start justify-content-between">
                        <div className="col-3">
                            <SwitchButton label={TranslationService.translate.Sort} onChange={x => setTimeout(() => getFeature(orderByFeatureName).toggleFeature(x), 5)} defaultValue={getFeature(orderByFeatureName).isOn} disabled={!getFeature("includeinvoices").isOn} />
                        </div>
                        {sortByItems.map((x, i) =>
                            <div key={x._key} className={"col-8" + (i === 0 ? "" : " offset-4")}>
                                <div className="row mb-3 align-items-center">
                                    <div className="col">
                                        <Dropdown onChange={x => { getFeature(orderByFeatureName).setFeatureValue("orderby" + i.toString() as "orderby0")(x); }} items={orderByFields} optionLabel={TranslationService.translate.Select} defaultValue={getOrderBy(model, i)} readonly={!getFeature(orderByFeatureName).isOn || !getFeature("includeinvoices").isOn} />
                                    </div>
                                    <div className="col-auto">
                                        <div className="d-flex align-items-center">
                                            <CheckBoxEditor message="" onChange={x => getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc" + i.toString() as "orderbyorderdesc0")(x ? "on" : "off")} defaultValue={getOrderByDesc(model, i) === "on"} disabled={!getFeature(orderByFeatureName).isOn || !getFeature("includeinvoices").isOn} />
                                            <i className="fa-regular fa-arrow-down-a-z fs-5 btnSort"></i>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="col-auto">
                                            <button className="btnTrash" type="button" onClick={() => deleteSortBy(i)} disabled={!getFeature(orderByFeatureName).isOn || !getFeature("includeinvoices").isOn}><i className="fa-regular fa-trash fs-5 text-danger"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {getFeature(orderByFeatureName).isOn && getFeature("includeinvoices").isOn && sortByItems.length < 3 && <div className="col-8 offset-4">
                            <button className="btn btn-link d-flex gap-1 align-items-center fw-normal p-1 fs-7" onClick={() => setSortByItems(x => [...x, { orderBy: undefined, orderbyorderdesc: "off", _key: v4() }])}>
                                <i className="far fa-plus"></i>{TranslationService.translate.AddOrder}
                            </button>
                        </div>}
                    </div>
                </>}
            </div>
        </div>
    </>;
};