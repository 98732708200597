import { useContext, useMemo, useState } from "react";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import InvoiceService, { InvoiceFields } from "../../../services/InvoiceService";
import ToastContext from "../../shared/bootstrap/Toast";
import Dropdown from "../../shared/components/Dropdown";
import { AutocompleteClient, DatePickerEditor, FileApi, FileListEditor, GroupCreatableEditor, TextEditor, TextEditorNumber } from "../../shared/components/Editors";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import moment from "moment";
import { formatDateDigitsMin } from "../../../utils/FormatUtils";
import { v4 } from "uuid";
import { GroupListResponse } from "../../group/entities/GroupListResponse";
import { getError } from "../../../utils/RequestUtils";
import { Switch } from "../../../utils/Utils";
import { useHistory } from "react-router-dom";

export class InvoiceModel {
    additionals: { Id: number; Value: number | string; }[] = [];
    files: { response: null, fileName: string, id: string }[] = [];
    status: number | null = null;
    IOID = 0;
    personId: number | null;
    amount: number | null = null;
    referenceNumber: string | null = null;
    dueDate: string | null = null;
    description: string | null = null;
    emittedDate: string = new Date().toJSON()
    ImportID: number | null = null;
    groupID: string | null = null;
    CurrencyID: number = CompanyService.getDefaultCurrencyId();
    IOStatusID: number | null = null;
    Tag_IO: [] = [];
    groupName: string | null = null;
    constructor(personId?: number) {
        this.personId = personId ?? null;
    }
}
const InvoiceNew = ({ reload, client = undefined }: { reload: () => void, client?: { PersonId: number, Name: string } }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [isSaving, setIsSaving] = useState(false);
    const [invoice, setInvoice] = useState(new InvoiceModel(client?.PersonId));
    const history = useHistory();

    const { fields, requiredManager } = useMemo(() => {
        const requiredManager = new RequiredManager();
        const fixedFields = [
            {
                title: CompanyService.getGroupName(),
                onChange: (value?: string) => setInvoice(invoice => ({ ...invoice, groupID: (value?.startsWith(newgroup) ? -1 : value) as unknown as string ?? null, groupName: value?.startsWith(newgroup) ? value.replace(newgroup, "") : null })),
                type: DataType.Group,
                fieldId: InvoiceFields.group,
                items: invoice.personId !== null ? [{ value: 0, text: invoice.personId.toString() }, { value: parseInt(invoice.groupID ?? "0"), text: invoice.groupName ?? "" }] : [],
            },
            {
                title: InvoiceService.getReferenceNumberName(),
                onChange: requiredManager.makeRequired((value?: string) => setInvoice(invoice => ({ ...invoice, referenceNumber: value ?? null }))) as (value?: string) => void,
                type: DataType.Text,
                fieldId: InvoiceFields.referenceNumber,
                items: [],
                defaultValue: invoice.referenceNumber,
            },
            {
                title: translate.Amount,
                onChange: requiredManager.makeRequired(
                    (value?: string) => Switch(
                        [!value, () => setInvoice(invoice => ({ ...invoice, amount: null }))],
                        [() => value!.startsWith("currencyId:"), () => setInvoice(invoice => ({ ...invoice, CurrencyID: parseFloat(value!.substring(11)) }))],
                        [true, () => setInvoice(invoice => ({ ...invoice, amount: parseFloat(value!) }))])) as (value?: string) => void,
                type: DataType.CurrencyWithCurrencyType,
                fieldId: InvoiceFields.amount,
                items: [],
                defaultValue: invoice.amount,
            },
            {
                title: translate.ExpirationDay,
                onChange: (value?: string) => setInvoice(invoice => ({ ...invoice, dueDate: value ? new Date(value).toJSON() : null })),
                type: DataType.Date,
                fieldId: InvoiceFields.dueDate,
                items: [],
            },
            {
                title: translate.Observations,
                onChange: (value?: string) => setInvoice(invoice => ({ ...invoice, description: value ?? null })),
                type: DataType.Text,
                fieldId: InvoiceFields.description,
                items: [],
            },
            {
                title: translate.IssueDate,
                onChange: requiredManager.makeRequired((value?: string) => setInvoice(invoice => ({ ...invoice, emittedDate: value ? new Date(value).toJSON() : "" }))) as (value?: string) => void,
                type: DataType.Date,
                fieldId: InvoiceFields.emittedDate,
                items: [],
                defaultValue: invoice.emittedDate ?? new Date().toString(),
            },
            {
                title: translate.IOStatus,
                onChange: (value?: string) => setInvoice(invoice => ({ ...invoice, IOStatusID: value ? parseInt(value) : null })),
                type: DataType.List,
                fieldId: InvoiceFields.status,
                items: [...CompanyService.getIoStatus().map(x => ({ value: x.IOStatusID, text: x.Value }))],
            },
        ];

        if (client === undefined) {
            fixedFields.push({
                title: translate.Customer,
                onChange: requiredManager.makeRequired((value: string) => setInvoice(invoice => ({ ...invoice, personId: parseInt(value) }))) as (value?: string) => void,
                type: DataType.Client,
                fieldId: InvoiceFields.client,
                items: [],
                defaultValue: invoice.personId,
            });
        }
        const additionalDefinitions = CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Invoice).map(x =>
        ({
            title: x.Name,
            onChange: (value?: string) => {
                setInvoice(invoice => {
                    const additionals = invoice.additionals.filter(y => y.Id !== x.AdditionalDefinitionID);
                    if (value !== undefined) {
                        additionals.push({ Id: x.AdditionalDefinitionID, Value: value?.toString() });
                    }
                    return ({ ...invoice, additionals });
                });
            },
            type: x.type as DataType,
            fieldId: x.AdditionalDefinitionID,
            items: x.AdditionalDefinitionItems.map(x => ({ value: x.AdditionalDefinitionItemID, text: x.Value })),
        }));

        const ids = CompanyService.getIoSortedFields().split(",").map(x => parseInt(x));
        ids.push(InvoiceFields.status);

        const getIndex = (fieldId: number) => {
            const index = ids.indexOf(fieldId);
            return index === -1 ? fieldId + 1000000 : index;
        };
        return {
            fields: additionalDefinitions.concat(fixedFields).sort((a, b) => getIndex(a.fieldId) - getIndex(b.fieldId)),
            requiredManager,
        };

    }, [client, invoice.amount, invoice.emittedDate, invoice.groupID, invoice.groupName, invoice.personId, invoice.referenceNumber, translate.Amount, translate.Customer, translate.ExpirationDay, translate.IOStatus, translate.IssueDate, translate.Observations]);

    const saveInvoice = async () => {
        if (isSaving) {
            return;
        }
        console.log("saveInvoice - ", { invoice })
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        setIsSaving(true);
        const result = await InvoiceService.set(invoice);
        setIsSaving(false);
        if (result instanceof Error) {
            const err = getError(result);
            let errorMessage = translate.ErrorProcessingRequest;
            if (err.status === 400) {
                if (err.message === "Fail-ReferenceNumber") {
                    errorMessage = translate.IdentifierAlreadyInUsed;
                }
            }
            showToast(errorMessage, undefined, "danger");
            return;
        }
        if (parseInt(invoice.groupID ?? "0") === -1) {
            history.go(0);
        } else {
            reload();
        }
        FloatingPanelService.hidePanel();
    };

    const onFileChange = (files: FileApi[]) => {
        setInvoice(invoice => ({ ...invoice, files: files.map(x => ({ fileName: x.name, response: null, id: x.id })) }));
    };

    return (
        <>
            <div className="floatingBody p-4">
                <div className='card-body'>
                    {client !== undefined &&
                        <div className="d-flex input-column mb-3">
                            <label className="form-label">{translate.Customer}</label>
                            <p className="form-label-detail ms-0 text-start">{client.Name}</p>
                        </div>}
                    <div className="row">
                        {fields.map(x =>
                            <InvoiceFieldEdit key={x.fieldId} {...x} />
                        )}
                    </div>
                    <FileListEditor onFilesChange={onFileChange} canEdit />
                </div>
            </div>
            <FloatingPanelFooter>
                <button className='btn btn-primary' onClick={saveInvoice}>
                    {translate.Create}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

const newgroup = v4();
type InvoiceFieldEditProps =
    {
        onChange: (val?: string) => void,
        type: DataType,
        items: { value: number, text: string }[],
        title: string, fieldId: number,
        defaultValue?: string,
    };

const InvoiceFieldEdit = ({ type, title, onChange, items, fieldId, defaultValue }: InvoiceFieldEditProps) => {
    let editor = undefined;
    switch (type) {
        case DataType.Client:
            editor = <AutocompleteClient onChange={onChange} />;
            break;
        case DataType.Group:
            editor = items.length === 2 ? <GroupCreatableEditor onChange={(value) => {
                onChange(value === null ? undefined : value.GroupID === -1 ?
                    (newgroup + value.Name) : value.GroupID.toString());
            }}
                defaultValue={items[1].value === 0 ? undefined : { GroupID: items[1].value, Name: items[1].text } as GroupListResponse.Item}
                personId={parseInt(items[0].text)} /> :
                <input type="text" readOnly className="form-control-plaintext"></input>;
            break;
        case DataType.List:
            if (items.length === 0)
                throw new Error("items missing");
            editor = <Dropdown onChange={(x) => onChange(x !== undefined ? x.toString() : x)} items={items} />;
            break;
        case DataType.Date:
            if (fieldId === InvoiceFields.dueDate) {
                editor = <DueDateEditor onChange={(newDate) => onChange(formatDateDigitsMin(newDate))} />;
            }
            else {
                editor = <DatePickerEditor onChange={(newDate) => onChange(formatDateDigitsMin(newDate))} defaultValue={defaultValue} />;
            }
            break;
        case DataType.Number:
        case DataType.Currency:
            editor = <input onChange={(event) => onChange(event.target.value)} type={"number"} className="form-control" />;
            break;
        case DataType.CurrencyWithCurrencyType:
            editor = <div className="row">
                <div className="col-4">
                    <Dropdown onChange={(value) => onChange("currencyId:" + value)} defaultValue={CompanyService.getDefaultCurrencyId()}
                        items={CompanyService.getCurrencies().map(x => ({ value: x.CurrencyId, text: x.Symbol }))} />
                </div>
                <div className="col-8">
                    <TextEditorNumber onChange={(val) => onChange(val)}></TextEditorNumber>
                    {/* <input onChange={(event) => onChange(event.target.value)} type={"number"} className="form-control" /> */}
                </div>
            </div>;
            break;
        case DataType.Text:
        case DataType.Phone:
        case DataType.Link:
            if (InvoiceFields.description === fieldId) {
                editor = <textarea onChange={event => onChange(event.target.value)} maxLength={250}
                    className="form-control" rows={3} style={{ resize: "none" }} defaultValue={defaultValue} />;
            } else {
                editor = <TextEditor onChange={onChange} />;
            }
            break;
        default:
            throw new Error("Missing editor: " + DataType[type]);
    }
    const col = fieldId === InvoiceFields.client || fieldId === InvoiceFields.description ? "col-12" : "col-6";
    return (
        <div className={col}>
            <div className="d-flex input-column mb-3">
                <label className="form-label">{title}</label>
                {editor}
                {<ValidationMessage onChange={onChange} defaultValue={defaultValue} />}
            </div>
        </div>
    );
};

const DueDateEditor = ({ onChange }: Pick<InvoiceFieldEditProps, "onChange">) => {
    const [showDays, setShowDays] = useState(false);
    const dayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const days = parseInt(event.target.value);
        if (!Number.isInteger(days)) {
            return;
        }
        onChange(moment().add(days, "days").toDate().toString());
    };
    return (
        <div className="row">
            <div className="col">
                <Dropdown items={[{ text: TranslationService.translate.ExactDate, value: false },
                { text: TranslationService.translate.NumberOfDays, value: true }]}
                    onChange={setShowDays} />
            </div>
            <div className="col">
                {showDays
                    ? <input type="number" className="form-control" onChange={dayChange}
                        placeholder={TranslationService.translate.Days} />
                    : <DatePickerEditor onChange={onChange} />
                }
            </div>
        </div>);
};
export default InvoiceNew;