import React, { CSSProperties, useRef, useState, } from "react";

type DragData = { fromElement: HTMLTableRowElement | undefined, fromIndex: number, toElement: HTMLTableRowElement | undefined, toIndex: number | undefined, originalPosition: ChildNode | null };
type DragEvents = {
    onDragEnter: (event: any) => void;
    onDragStartCapture: (event: any) => void;
    onDragEnd: (e: React.DragEvent<HTMLTableRowElement>) => void;
    draggable: boolean;
}
const OrderableTable = <T,>({ data: origData, RowComponent, tableClass, headers, getKey, onSort: onSortEnd, reload, valueSearch }: { data: T[], RowComponent: (props: { data: T, dragEvents: DragEvents, reload: () => void, valueSearch?: string }) => JSX.Element, tableClass?: undefined | string, headers: { title: string, style?: undefined | CSSProperties }[], getKey: (obj: T) => string, onSort: (data: T[]) => void, reload: () => void, valueSearch?: string }) => {
    const [data, setData] = useState(origData);
    const dragData = useRef<DragData>({ fromElement: undefined, fromIndex: 0, toElement: undefined, toIndex: undefined, originalPosition: null });

    const swap = (first: HTMLTableRowElement, second: HTMLTableRowElement) => {
        if (first === second) {
            return;
        }
        if (first.nextElementSibling === second) {
            first.parentElement?.insertBefore(first, second.nextElementSibling);
        } else {
            first.parentElement?.insertBefore(first, second);
        }
    }

    const onDragEndCapture = (e: React.DragEvent<HTMLTableRowElement>) => {
        const element = e.target as HTMLTableRowElement;
        element.classList.remove("hidden");
        element.parentElement?.parentElement?.classList.add("shadow-hover");

        if (dragData.current.fromElement === element) {
            element.parentElement?.insertBefore(element, dragData.current.originalPosition);
        }
        dragData.current.fromElement = undefined;
        dragData.current.toElement = undefined;
    }

    const onDragStartCapture = (e: React.DragEvent<HTMLTableRowElement>, index: number) => {
        const element = e.target as HTMLTableRowElement;
        if (element.dataset["noDrag"]) {
            return;
        }
        setTimeout(() => element.classList.add("hidden"), 50);
        element.parentElement?.parentElement?.classList.remove("shadow-hover");
        dragData.current.fromIndex = index;
        dragData.current.fromElement = element;
        dragData.current.toElement = element;
        dragData.current.toIndex = index;
        dragData.current.originalPosition = element.nextSibling;
    }

    const onDragEnterCapture = (e: React.DragEvent<HTMLTableRowElement>, index: number) => {
        const element = e.currentTarget as HTMLTableRowElement;
        if (!dragData.current.fromElement || !element.draggable) {
            return;
        }
        if (dragData.current.fromIndex !== index) {
            dragData.current.toIndex = index;
        }
        dragData.current.toElement = element;
        swap(dragData.current.fromElement, element);
    }

    const onDropCapture = (e: React.DragEvent) => {
        if (dragData.current.toIndex === undefined || !dragData.current.fromElement || !dragData.current.toElement) {
            return;
        }
        dragData.current.fromElement.classList.remove("hidden");
        dragData.current.fromElement = undefined;

        if (!data) {
            return undefined;
        }
        const targetIndex = dragData.current.toIndex;
        const item = data.splice(dragData.current.fromIndex, 1)[0];
        data.splice(targetIndex, 0, item);
        onSortEnd(data);
        setData([...data]);
    }

    return (
        <table className={"table " + tableClass} onDragOver={preventDefault} onDrop={onDropCapture}>
            <thead>
                <tr className="cursor-default">
                    {headers.map(x => <th key={x.title} style={x.style}>{x.title}</th>)}
                </tr>
            </thead>
            <tbody>
                {data
                    .filter(z => z)
                    .map((x, index) => (
                        <RowComponent key={getKey(x)} data={x} dragEvents={{
                            onDragEnter: (event) => onDragEnterCapture(event, index),
                            onDragStartCapture: (event) => onDragStartCapture(event, index),
                            onDragEnd: onDragEndCapture,
                            draggable: true
                        }}
                            reload={reload}
                            valueSearch={valueSearch} />
                    ))}
            </tbody>
        </table>
    )
}

function preventDefault(e: { preventDefault: () => void, stopPropagation: () => void }) {
    e.preventDefault();
    e.stopPropagation();
}

export default OrderableTable;