import { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ActivityService from "../../../../services/ActivityService";
import CompanyService, { DataType } from "../../../../services/CompanyService";
import { FilterCollection } from "../../../../services/FilterService";
import InvoiceService from "../../../../services/InvoiceService";
import { formatDateDigits, formatDateShort } from "../../../../utils/FormatUtils";
import ModalService from "../../../shared/bootstrap/Modal";
import ToastContext, { ToastService } from "../../../shared/bootstrap/Toast";
import AdvancedFilters, { AdvancedFiltersButton, AdvancedFiltersContext } from "../../../shared/components/AdvancedFilters";
import Dropdown from "../../../shared/components/Dropdown";
import { AutocompleteClient, AutocompleteGroup, DatePickerEditor, FileApi, MultiselectEditor, TagsDropdownEditor, TextAreaBigEditor, TextAreaBigEditorFull } from "../../../shared/components/Editors";
import { SortDirection } from "../../../shared/entities/Sort";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from "../../../../services/TranslationService";
import { RequiredManager, ValidationMessage, ValidationMessageProps } from "../../../shared/RequieredManager";
import Table, { TableHeader } from "../../../shared/Table";
import TableContext, { TableContextValues } from "../../../task/TableContext";
import ClientDetailContext, { ClientDetailProvider } from "../../ClientDetailContext";
import InvoiceTableProvider from "../../invoice/InvoiceTableProvider";
import { ActivityGetResponse } from "../entities/AcitivityGetResponse";
import { ActivityListResponse } from "../entities/AcitivityListResponse";
import { ActivitySetComment } from "../entities/ActivitySetComment";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import TagService from "../../../../services/TagService";
import { MapIfHasHours as MapIfHasTime, addTimezone, restTimezone } from "../../../../utils/ParseUtils";
import { addIf, Cast, OptionalMap } from "../../../../utils/Utils";
import FileService from "../../../../services/FileService";
import AdvancedFilterService from "../../../../services/AdvancedFilterService";
import TooltipComponent from "../../../shared/TooltipComponent";
import { ButtonTooltipIcon } from "../../../shared/components/ButtonTooltipIcon";
import InvoiceTableItemReadOnly from "../../invoice/InvoiceTableItemReadOnly";
import { MultiFileUpload } from "../../../shared/components/MultiFileUpload";

export const NewComment = ({ reload, clientid, data: original = undefined, handleClose }: { reload: () => void, clientid: string, data?: ActivityListResponse.Item, handleClose?: () => void }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [invoiceIds, setInvoiceIds] = useState<number[]>([]);

    const [isSaving, setIsSaving] = useState(false);
    const requiredManager = useRef(new RequiredManager()).current;
    const [filters, setFilters] = useState<string[]>([]);
    const [fixedIos, setFixedIos] = useState<ActivityGetResponse["ios"]>();
    const [loadingFixedIos, setLoadingFixedIos] = useState(true);

    const createCommentModel = (original?: ActivityListResponse.Item): ActivitySetComment => {
        if (original === undefined) {
            return {
                item: {
                    PersonID: parseInt(clientid),
                    Content: "",
                    files: [],
                    ActivityTypeID: CompanyService.getActivityTypes().find(x => x.Comment && x.CommentDefault)?.ActivityTypeID,
                },
                filter: "",
                ids: "",
                quickfilter: undefined,
            };
        }
        const item = { ...original, PersonID: original.PersonId };
        for (const key of Object.keys(item).filter(x => !["ID", "Content", "PersonID", "Sent", "GroupID", "TaskID", "ActivityTypeID", "NotifyTo", "Tag_Message", "files", "MessageID", "tagList "].includes(x))) {
            delete item[key as keyof typeof item];
        }
        return Cast<ActivitySetComment>({
            item: { ...item, Sent: original.date, ActivityTypeID: original.ActivityTypeID, Tag_Message: original.Tags?.map(x => ({ TagID: x.TagID })) },
        });
    };
    const [comment, setComment] = useState<ActivitySetComment>(createCommentModel(original));
    const [files, setFiles] = useState<{ id: string; name: string; }[]>(original?.files.map(x => ({ id: x.id, name: x.fileName })) ?? []);

    const requestData = useCallback(
        async () => {
            if (original === undefined) {
                setLoadingFixedIos(false);
                return;
            }
            const result = await ActivityService.get(original?.ID);
            if (result instanceof Error) {
                setLoadingFixedIos(false);
                return;
            }
            setComment(comment => ({ ...comment, item: { ...comment.item, MessageID: result.item.MessageID } }));
            setLoadingFixedIos(false);
            if (result.ios !== undefined && result.ios.length > 0) {
                setFixedIos(result.ios);
            }
        },
        [original],
    );

    useEffect(() => {
        requestData();
    }, [requestData]);

    const fields: {
        title: string;
        onChange: (value: string) => void;
        type: DataType;
        items: { value: string; text: string; }[],
        defaultValue?: (current: ActivitySetComment) => string | undefined;
        className: string;
        secondaryVariant: boolean;
        placeholder?: string;
        extraStyle?: React.CSSProperties
    }[] = useMemo(() => [
        {
            title: translate.Date,
            onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, Sent: value } })),
            type: CompanyService.getSetting("canselectactivitydate") ? DataType.Date : DataType.Readonly,
            items: [],
            defaultValue: () => (CompanyService.getSetting("canselectactivitydate") ?
                (original?.date || formatDateShort(OptionalMap(original?.date, x => new Date(x)) ?? new Date())) :
                formatDateShort(OptionalMap(original?.date, x => new Date(x)) ?? new Date())
            ),
            className: "col-4",
            secondaryVariant: false,
        },
        {
            title: translate.ActivityType2,
            onChange: requiredManager.makeRequiredIf(
                CompanyService.getSetting("mandatorycommenttype"),
                (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, ActivityTypeID: value ? parseInt(value) : undefined } })),
                "activitytype"),
            type: DataType.List,
            items: [{ value: "", text: translate.None }, ...CompanyService.getActivityTypes().filter(x => x.Comment).map(x => ({ value: x.ActivityTypeID.toString(), text: x.ActivityTypeName }))],
            defaultValue: (current) => current.item?.ActivityTypeID?.toString(),
            className: "col-8",
            secondaryVariant: false
        },
        ...addIf(CompanyService.getGroupName()?.length > 0, {
            title: CompanyService.getGroupName(),
            onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, GroupID: parseInt(value) } })),
            type: DataType.Group,
            items: [{ value: "0", text: clientid }],
            defaultValue: () => original?.groupId,
            className: "col-12",
            secondaryVariant: false
        }),
        {
            title: "",
            onChange: requiredManager.makeRequiredWithId((value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, Content: value } })), "content"),
            type: DataType.CommentsLink,
            items: [],
            defaultValue: (current) => current?.item.Content,
            className: "col-12 p-0 m-0",
            secondaryVariant: false,
            placeholder: translate.WriteComment,
            extraStyle: { fontSize: 16, borderRadius: 0 }
        },

        // {
        //     title: translate.NotifyTo,
        //     onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, NotifyTo: value } })),
        //     type: DataType.Multiselect,
        //     items: CompanyService.getUsers().map(x => ({ value: x.Id, text: x.Value })),
        //     defaultValue: (current) => undefined,
        //     className: "col-6",
        //     secondaryVariant: false
        // },
        // ...(CompanyService.getTags()?.length ? [{
        //     title: translate.Tags,
        //     onChange: requiredManager.makeRequiredIf(CompanyService.getSetting("mandatorytag"), (value: string) => {
        //         setComment(comment => ({ ...comment, item: { ...comment.item, Tag_Message: value.split(", ").filter(x => x && x.length > 0).map(x => ({ TagID: parseInt(x) })) } }));
        //     }, "comment"),
        //     type: DataType.Tags,
        //     items: CompanyService.getTags().map(x => ({ value: x.Id, text: x.Value })),
        //     defaultValue: (current: ActivitySetComment) => current.item?.Tag_Message?.map(x => x.TagID).join(","),
        //     className: "col-6",
        //     secondaryVariant: false
        // }] : []),
    ], [clientid, original, requiredManager, translate.ActivityType2, translate.Date, translate.None, translate.WriteComment]);
    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    useEffect(() => {
        advancedFiltersContext.setShow(comment.quickfilter === 3);
    }, [advancedFiltersContext, comment.quickfilter]);

    const saveComment = async () => {
        if (isSaving) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        if (CompanyService.getSetting("mandatoryeffectiveactivityfile") && !comment.item.files?.length) {
            ToastService.showToast(TranslationService.translate.YouHaveToAttachAtLeastOneFileInAnEffectiveActivity, undefined, "warning");
            return;
        }
        setIsSaving(true);
        {
            const result = await TagService.createMissingTags(comment.item.Tag_Message?.map(x => x.TagID).join(", "));
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setIsSaving(false);
                return;
            }
            const val = result?.split(",").filter(x => x && x.length > 0).map(x => ({ TagID: parseInt(x) }));
            setComment(comment => ({ ...comment, item: { ...comment.item, Tag_Message: val } }));
            comment.item.Tag_Message = val;
        }

        if (comment.quickfilter === 3) {
            comment.filter = AdvancedFilterService.filterStringsToQueryString(filters);
        }

        if (comment.quickfilter === 6) {
            comment.ids = invoiceIds.join(",");
        }
        const result = await ActivityService.setComment(comment);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setIsSaving(false);
            return;
        }
        await requestData();
        handleClose && await handleClose()
        setIsSaving(false);

        FloatingPanelService.hidePanel();
        reload();
    };

    const onFileChange = (files: FileApi[]) => {
        setComment(comment => ({ ...comment, item: { ...comment.item, files: files.map(x => ({ fileName: x.name, response: null, id: x.id })) } }));
        setFiles([...files]);
    };

    const onFilterChange = (value: string) => setComment(comment => ({ ...comment, filter: value }));
    const tableContext = new TableContextValues();
    tableContext.applyFilters = onFilterChange;

    const tableHeaders: TableHeader[] = [
        ...InvoiceService.getTableHeaders(),
    ];

    const tableValues = new TableContextValues();
    tableValues.error = false;
    tableValues.response = { list: fixedIos };
    tableValues.loading = false;
    tableValues.sort = { sortColumn: "", sortDirection: SortDirection.Ascending };
    tableValues.setSortColumn = () => Boolean(4);

    const changeSelection = () => {
        ModalService.showDefaultModal({
            acceptButtonLabel: "Ok",
            message: translate.ChangeSelectionMessage,
            onAcceptClick: () => {
                setFixedIos(undefined); setComment(x => ({
                    ...x,
                    quickfilter: null //Cast<number>(0)
                }));
                setLoadingFixedIos(false);
            },
            title: translate.ChangeSelection
        });
    };
    return (
        <>
            <div className="floatingBody">
                <div className="row">
                    {fields.map(x =>
                        <CommentFieldEdit key={x.title?.replaceAll(" ", "")} {...x} defaultValue={x.defaultValue && x.defaultValue(comment)} />
                    )}

                    <ActionButtons
                        original={original}
                        onFilesChange={onFileChange}
                        canEdit={true}
                        files={files}
                        setComment={setComment}
                        comment={comment}
                        requiredManager={requiredManager}
                        downloadFile={(id: any, name: any) => FileService.downloadFileMessage(id, original?.ID.toString() ?? "", name)}
                        linkedData={
                            <>
                                {fixedIos !== undefined && CompanyService.getSetting("messageiolink") && <div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <h6 className="mt-2">{translate.LinkedInvoices}</h6>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-intiza" onClick={changeSelection}>{translate.ChangeSelection}</button>
                                        </div>
                                    </div>
                                    <TableContext.Provider value={tableValues}>
                                        <Table headers={tableHeaders} item={InvoiceTableItemReadOnly(undefined)} />
                                    </TableContext.Provider>
                                </div>}
                                {loadingFixedIos ?
                                    <div className="col-11 align-items-center">
                                        <i className="fas fa-spinner-third fa-spin third ms-2"></i>
                                    </div>
                                    :
                                    (fixedIos === undefined && CompanyService.getSetting("messageiolink")) &&
                                    <CommentFieldEdit key={translate.LinkCommentWith.replaceAll(" ", "")}
                                        marginBottom=""
                                        label={false}
                                        {...{
                                            title: undefined,
                                            onChange: requiredManager.makeRequiredIf(CompanyService.getSetting(Cast<"mandatoryiolistincomment">("mandatoryiolistincomment")), (value: string) => setComment(comment => ({ ...comment, quickfilter: value === "" ? null : parseInt(value) })), "iocomment"),
                                            type: DataType.List,
                                            items: [ //due = 0, pending = 1, all = 2, filtered = 3, (Hay que incluir filter) claimable = 4, dueclaimable = 5, selected = 6 (Hay que incluir ids)
                                                { text: translate.NoInvoices, value: "" },
                                                { text: translate.PendingInvoices, value: "1" },
                                                { text: translate.AllPendingClaimable, value: "4" },
                                                { text: translate.DuesClaimable, value: "5" },
                                                { text: translate.DueInvoices, value: "0" },
                                                { text: translate.OtherFilters, value: "3" },
                                                { text: translate.SelectFromAList, value: "6" },
                                            ],
                                            defaultValue: comment.quickfilter?.toString(),
                                            className: "col-12"
                                        }} />
                                }
                            </>}
                        linkedDataInvoice={
                            <div className="">
                                {comment.quickfilter === 6 &&
                                    <ClientDetailProvider>
                                        <InvoiceList personId={parseInt(clientid)} setIds={setInvoiceIds} />
                                    </ClientDetailProvider>
                                }
                            </div>}
                        otherFilter={
                            <div className="ps-3 pe-4">
                                <AdvancedFilters page={FilterCollection.Invoice} onFilterApply={setFilters} />
                            </div>
                        }

                    />

                </div>
            </div>
            <FloatingPanelFooter>
                <button className="btn btn-primary" onClick={saveComment}>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

const ActionButtons = ({
    onFilesChange,
    fileDelete,
    files: filesAux,
    downloadFile: downloadFileAux,
    fileUpload: fileUploadAux,
    linkedData,
    canEdit,
    requiredManager,
    setComment,
    comment,
    linkedDataInvoice,
    original,
    otherFilter
}: any) => {
    const { translate } = TranslationService;
    const tagsValues = useMemo(() => comment.item?.Tag_Message?.map((x: any) => x.TagID.toString()) || [], [comment.item?.Tag_Message]);
    const defaultPersonId = useMemo(() => (comment.item?.PersonID.toString()), [comment.item?.PersonID]);


    const fields = useMemo(() => [
        {
            title: translate.NotifyTo,
            onChange: (value: string) => setComment((comment: any) => ({ ...comment, item: { ...comment.item, NotifyTo: value } })),
            type: DataType.Multiselect,
            items: CompanyService.getUsers().map(x => ({ value: x.Id, text: x.Value })),
            className: "col-11",
            secondaryVariant: false
        },
        ...(CompanyService.getTags()?.length ? [{
            title: translate.Tags,
            onChange: requiredManager.makeRequiredIf(CompanyService.getSetting("mandatorytag"), (value: string) => {
                setComment((comment: any) => ({ ...comment, item: { ...comment.item, Tag_Message: value.split(", ").filter(x => x && x.length > 0).map(x => ({ TagID: parseInt(x) })) } }));
            }, "comment"),
            type: DataType.Tags,
            items: CompanyService.getTags().map(x => ({ value: x.Id, text: x.Value })),
            className: "col-11",
            secondaryVariant: false
        }] : [])
    ], [requiredManager, setComment, translate.NotifyTo, translate.Tags]);

    return (
        <>
            <div className="col-12">
                <div className="row mb-1">
                    <div className="ps-4 pb-1">
                        <MultiFileUpload onFilesChange={onFilesChange} canEdit downloadFile={downloadFileAux} />
                    </div>
                </div>
            </div>
            {fields[0] && [fields[0]]?.map(x =>
                <div className="d-flex align-items-center">
                    <ButtonTooltipIcon icon="fa fa-share-alt" isLink={false} title={x.title} onClick={() => { }} />
                    <CommentFieldEdit marginBottom="" key={x.title?.replaceAll(" ", "")} {...x} title={""} defaultValue={defaultPersonId} label={false} />
                </div>
            )}

            {fields[1] && [fields[1]].map(x =>
                <div className="d-flex align-items-center my-3">
                    <ButtonTooltipIcon icon="fa fa-tags" isLink={false} title={x.title} onClick={() => { }} />
                    <CommentFieldEdit marginBottom="" key={x?.title?.replaceAll(" ", "")} {...x} title={""} defaultValue={tagsValues.join(", ")} label={false} />
                </div>
            )}

            <div className="col-12">
                <div className="row mb-1">

                    <div className="d-flex align-items-start">
                        <ButtonTooltipIcon icon="fa fa-notes" isLink={false} title={TranslationService.translate.LinkCommentWith} onClick={() => { }} />
                        <div className="col-11">
                            {linkedData}
                        </div>
                    </div>
                    {linkedDataInvoice}
                    <div className="d-flex align-items-center my-2">
                        <div className="col">
                            {otherFilter}
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export const FilesAttached = ({ files, canEdit, downloadFile, removeFile }: any) => {
    return (
        <>
            {files.length > 0 &&
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: 10
                }}>
                    {files.map((x: any) => (
                        <FileItem key={x.id} {...{ canEdit, removeFile, downloadFile, x }} />
                    ))}
                </div>
            }
        </>
    );
};

const FileItem = ({ canEdit, removeFile, downloadFile, x }: any) => {
    const [hoveredFileId, setHoveredFileId] = useState<string | null>(null);

    return (
        <div
            onMouseEnter={() => setHoveredFileId(x.id)}
            onMouseLeave={() => setHoveredFileId(null)}
        >
            <LabelActionText bgColor={hoveredFileId} text={x?.name || x?.fileName} action={() => downloadFile(x.id, x.name)} />
            {(canEdit && removeFile && hoveredFileId) &&
                <TooltipComponent title={TranslationService.translate.Delete}>
                    <button
                        className="btn"
                        style={{
                            backgroundColor: "#ff9f9f",
                            borderRadius: 0,
                            paddingLeft: 7,
                            paddingRight: 7,
                            paddingTop: 0,
                            paddingBottom: 0,
                            color: "white"
                        }}
                        onClick={() => removeFile(x.id)}
                    >
                        <i className="far fa-times"></i>
                    </button>
                </TooltipComponent>}
        </div>
    );
};

export const LabelActionText = ({ text, action, bgColor = true }: any) => {
    return <div className="btn cursor-pointer px-1 pb-2" style={{ lineHeight: 0.5, backgroundColor: (bgColor ? "#EAF8FA" : "#ebebeb"), justifyContent: "space-between", borderRadius: 0 }} onClick={action}>
        <span style={{ fontSize: 12, fontStyle: "italic" }} className="text-secondary">{text}</span>
    </div>;
};

const CommentFieldEdit = ({ type, title, onChange, className, items, defaultValue = undefined, secondaryVariant = false, placeholder = "", extraStyle = {}, marginBottom = "mb-3", label = true }: { onChange: (value: string) => void, type: DataType, title: string | undefined, className: string, items: { value: string, text: string }[], defaultValue?: string | undefined, secondaryVariant?: boolean, placeholder?: string, extraStyle?: React.CSSProperties, marginBottom?: string, label?: boolean }) => {
    let editor = undefined;
    let titleEditor = undefined;
    let validationEditor = undefined;
    let validationMethod: ValidationMessageProps<string>["validationMethod"] = undefined;

    switch (type) {
        case DataType.Client:
            editor = <AutocompleteClient onChange={onChange} defaultValue={defaultValue} />;
            break;
        case DataType.Group:
            editor = items.length === 1 ? <AutocompleteGroup onChange={(value) => onChange(value!.value)} clientId={items[0].text} /> : <input type="text" readOnly className="form-control-plaintext"></input>;
            break;
        case DataType.List:
            if (items.length === 0)
                throw new Error("items missing");
            editor = <Dropdown onChange={onChange} items={items} defaultValue={defaultValue} secondaryVariant={secondaryVariant} />;
            break;
        case DataType.Date:
            editor = <DatePickerEditor showIcon onChange={(x) => onChange(formatDateDigits(MapIfHasTime(new Date(x), addTimezone), "en"))} defaultValue={defaultValue ? MapIfHasTime(new Date(defaultValue), restTimezone)!.toString() : defaultValue} />;
            break;
        case DataType.Number:
        case DataType.Currency:
            editor = <input onChange={(event) => onChange(event.target.value)} type={"number"} className="form-control" />;
            break;
        case DataType.CurrencyWithCurrencyType:
            editor = <div className="row">
                <div className="col-4">
                    <Dropdown onChange={(value: string | number) => onChange("currencyId:" + value)}
                        items={CompanyService.getCurrencies().map(x => ({ value: x.CurrencyId, text: x.Symbol }))} />
                </div>
                <div className="col-8">
                    <input onChange={(event) => onChange(event.target.value)} type={"number"} className="form-control" />
                </div>
            </div>;
            break;
        case DataType.Text:
        case DataType.Phone:
        case DataType.Link:
            editor = <TextAreaBigEditor onChange={onChange} defaultValue={defaultValue} extraStyle={extraStyle} placeholder={placeholder} />;
            validationMethod = (value: string | undefined) => value !== undefined && value.length > 0;
            break;
        case DataType.CommentsLink:
            editor = <TextAreaBigEditorFull id='commentsBox' onChange={onChange} defaultValue={defaultValue} extraStyle={extraStyle} placeholder={placeholder} />;
            validationMethod = (value: string | undefined) => value !== undefined && value.length > 0;
            break;
        case DataType.Multiselect:
            {
                const selectItems = items.map(x => ({ value: x.value.toString(), label: x.text }));
                const def = defaultValue?.split(",").map(x => selectItems.find(y => y.value === x)!).filter(x => x !== undefined);
                // No viene el id del notificado, por ende no matchea el default en la edicion
                editor = <MultiselectEditor items={selectItems} callback={x => onChange(x ? x.join(",") : "")} defaultValue={def} />;
            }
            break;
        case DataType.Tags:
            editor = <TagsDropdownEditor onChange={(value) => {
                onChange(value);
            }} defaultValue={defaultValue} />;
            break;
        case DataType.Readonly:
            editor = <>{defaultValue}</>;
            break;
        default:
            throw new Error("Missing editor: " + DataType[type]);
    }

    switch (title) {
        case TranslationService.translate.Comment:
            titleEditor = <label className="form-label" style={{ paddingLeft: 14 }}>{title}</label>;
            break;
        default:
            titleEditor = <label className="form-label">{title}</label>;
            break;
    }

    switch (type) {
        case DataType.CommentsLink:
            validationEditor = <div style={{ paddingLeft: 14 }}><ValidationMessage onChange={onChange} defaultValue={defaultValue} validationMethod={validationMethod} /></div>;
            break;

        default:
            validationEditor = <ValidationMessage onChange={onChange} defaultValue={defaultValue} validationMethod={validationMethod} />;
            break;
    }

    return (
        <div className={className}>
            <div className={`d-flex input-column ${marginBottom}`}>
                {label && titleEditor}
                {editor}
                {validationEditor}
            </div>
        </div>
    );
};

export const InvoiceList = ({ personId, style, setIds }: { personId: number, style?: CSSProperties, setIds: (ids: number[]) => void }) => {
    const { invoiceIds, setInvoiceIds } = useContext(ClientDetailContext);

    const toggleOneCheckbox = (invoiceId: number, checked: boolean) => {
        let newIds: number[] = [];
        if (checked) {
            newIds = [...invoiceIds, invoiceId];
        }
        else {
            newIds = invoiceIds.filter(x => x !== invoiceId);
        }
        setInvoiceIds(newIds);
        setIds(newIds);
    };

    const tableHeaders: TableHeader[] = [
        new TableHeader("Checkbox", () => <></>, false, false),
        ...InvoiceService.getTableHeaders()
    ];

    return (
        <InvoiceTableProvider clientId={personId}>
            <div className="d-flex align-items-start my-3">
                <AdvancedFiltersButton />
                <AdvancedFilters page={FilterCollection.Invoice} />
            </div>
            <div className="d-flex align-items-start">
                <Table headers={tableHeaders} item={InvoiceTableItemReadOnly(toggleOneCheckbox)} />
            </div>
        </InvoiceTableProvider>
    );
};

export default NewComment;