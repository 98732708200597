import { useCallback, useEffect, useState } from "react";
import EmailTemplateService, { EmailTemplateListResponse } from "../../../services/EmailTemplateService";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import EmailTemplateEdit from "./EmailTemplateEdit";

const EmailTemplateConfiguration = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<EmailTemplateListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const requestData = async () => {
        const result = await EmailTemplateService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    const showEmailTemplateEditModal = (EmailTemplate?: EmailTemplateListResponse["list"][number]) => {
        FloatingPanelService.showPanel({
            children: <EmailTemplateEdit reload={requestDataCallback} current={EmailTemplate} />,
            title: translate.EmailTemplates,
            width: 600,
            height: 600,
            position: "center",
        })
    }

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.EmailTemplates}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showEmailTemplateEditModal(undefined)}><i className="fal fa-plus" /> {translate.AddNewTemplate}</button>
                </div>
            </div>
            <table className="table shadow-hover">
                <thead>
                    <tr>
                        <th>{translate.Template}</th>
                        <th>{translate.Subject}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {response?.list.map(x => (
                        <tr key={x.MailTemplateID} className="pointer" onClick={() => showEmailTemplateEditModal(x)}>
                            <td>{x.Name}</td>
                            <td>{x.Subject}</td>
                            <td width={200}><button className="edit"><i className="far fa-pen undefined"></i></button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
           
        </div>
    )
}
export default EmailTemplateConfiguration;