import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CompanyService from "../../../services/CompanyService";
import FloatingPanelService from "../../shared/FloatingPanel";
import ToastContext, { ToastService } from "../../shared/bootstrap/Toast";
import ActivityService, { EmailInvoiceListType, SetEmailRequest } from "../../../services/ActivityService";
import { RequiredManager } from "../../shared/RequieredManager";
import { ClientDetailContextValues } from "../ClientDetailContext";
import FilterService from "../../../services/FilterService";
import { ActivityGetResponse } from "../activity/entities/AcitivityGetResponse";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import TabMenu, { TabMenuProps } from "../../shared/components/TabMenu";
import { EmailTabMessage } from "./EmailTabMessage";
import { EmailTabInvoice } from "./EmailTabInvoice";
import { EmailTabAdditional } from "./EmailTabAdditional";
import { EmailTabPreview } from "./EmailTabPreview";
import { getError } from "../../../utils/RequestUtils";
import { OptionalMap } from "../../../utils/Utils";
export class EmailEdit {
    to = "";
    subject = "";
    toCC?: string;
    toBCC?: string;
    templateId?: string;
    groupId?: string;
    linkToClient = false;
    emailFrameId?: number;
    sendCopy = false;
    activityTypeId?: number;
    tags?: string;
    programEmail = false;
    programEmailDateTime?: string;
    MessageID?: number;
    bodyHtml = "";
    replacebody: "0" | "1" = "1";
    replytoid: number | null = null;

    includeInvoiceList = false;
    includeInvoiceFiles = false;
    invoiceListType: EmailInvoiceListType = EmailInvoiceListType.All;
    invoiceListMode = "1";
    invoiceGroupBy?: string;
    invoiceReportExportId?: number;
    invoiceLanguage?: string;
    invoiceFilters: string[] = [];

    constructor(subject?: string, to?: string, groupId?: string, emailBase?: ActivityGetResponse.Item, prevMessageId?: string) {
        this.to = emailBase?.To ?? to ?? "";
        this.subject = emailBase?.Subject ?? subject ?? "";
        this.groupId = emailBase?.GroupID ?? groupId;

        this.toCC = emailBase?.CC;
        this.toBCC = emailBase?.BCC;
        this.linkToClient = emailBase?.IncludeLink ?? false;
        this.sendCopy = emailBase?.copyMe ?? false;
        this.activityTypeId = emailBase?.ActivityTypeID;
        this.tags = emailBase?.Tag_Message.map(x => x.TagID).join(",");
        this.programEmailDateTime = emailBase?.Draft ? undefined : emailBase?.ScheduledDate;
        this.programEmail = !!this.programEmailDateTime;

        //this.includeInvoiceList = !!this.invoiceListType;
        this.includeInvoiceFiles = emailBase?.IncludeAttachment ?? false;
        this.invoiceGroupBy = emailBase?.Mail_GroupBy?.toString();
        this.invoiceReportExportId = emailBase?.ReportExportID;
        this.invoiceLanguage = emailBase?.Language;
        this.programEmailDateTime = emailBase?.ScheduledDate;
        this.programEmail = !!this.programEmailDateTime;
        this.invoiceFilters = FilterService.ParseExtraFiltersResponseString(emailBase?.IOFilter);
        this.emailFrameId = emailBase?.MailFrameID ? parseInt(emailBase?.MailFrameID) : undefined;
        this.MessageID = emailBase?.MessageID;
        this.replytoid = prevMessageId ? parseInt(prevMessageId) : null;
    }
}

interface EmailComposeParams {
    personId: string,
    onSubmit?: () => void,
    clientDetailContext?: ClientDetailContextValues,
    defaultSubject?: string
    defaultTo?: string
    defaultGroupId?: string
    defaultGroupLabel?: string
    prevMessageId?: string
    emailBase?: ActivityGetResponse.Item
}

export type EmailTabProps = {
    model: Partial<EmailComposeModel>,
    setModel: React.Dispatch<React.SetStateAction<Partial<EmailComposeModel>>>,
    getFeature: <TKey extends keyof EmailComposeModel["item"], >(featureKey: TKey, offValue?: EmailComposeModel["item"][TKey]) =>
        {
            toggleFeature: (isOn?: boolean | undefined) => void;
            setFeatureValue: <TValueKey extends keyof EmailComposeModel["item"], >(key: TValueKey) => (value: EmailComposeModel["item"][TValueKey]) => void;
            isOn: boolean;
        },
    requiredManager: RequiredManager,
};

export type EmailComposeModel = SetEmailRequest & {
    temp: {
        showPrevEmailBtn: boolean | "loading",
        includeInvoiceList?: boolean,
        invoiceListType?: EmailInvoiceListType,
    }
};

export const EmailCompose = (props: EmailComposeParams) => {
    const { onSubmit, clientDetailContext } = props;
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    const [model, setModel] = useState(getDefaultModel(props));
    function getDefaultModel(props: EmailComposeParams) {
        const { personId, defaultSubject, defaultTo, defaultGroupId, prevMessageId, emailBase } = props;
        const model = { item: {}, temp: {}, } as Partial<EmailComposeModel>;
        const item = model.item!;
        item.to = emailBase?.To ?? defaultTo ?? "";
        item.from = CompanyService.getUserEmail();

        item.subject = emailBase?.Subject ?? defaultSubject ?? "";
        item.groupid = emailBase?.GroupID ?? defaultGroupId;
        item.groupName = emailBase?.Group;

        item.cc = emailBase?.CC;
        item.bcc = emailBase?.BCC;
        item.copyme = emailBase?.copyMe ?? false;
        item.activitytypeid = emailBase?.ActivityTypeID;
        item.Tag_Message = emailBase?.Tag_Message;
        if (new Date(emailBase?.ScheduledDate).getFullYear() < 2099) {
            item.scheduleddate = emailBase?.ScheduledDate;
        }
        item.personid = parseInt(personId);
        item.fromname = CompanyService.getUsers().find(x => x.Id === CompanyService.getUserid())?.Value;
        item.html = true;
        item.attach = emailBase?.Attach ?? false;
        //Content set later in useEffect if undefined
        item.content = emailBase?.Content as string;

        //item.includeInvoiceList = !!item.invoiceListType;
        //Invoice
        item.includeinvoices = Boolean(emailBase?.IncludeInvoices);
        item.listmode = emailBase?.Attach ? "1" : "0";
        item.IncludeAttachment = emailBase?.IncludeAttachment ?? false;
        item.includeattachment = emailBase?.IncludeAttachment ?? false;
        item.mail_groupby = emailBase?.Mail_GroupBy ? parseInt(emailBase.Mail_GroupBy) : OptionalMap(CompanyService.getCompanyAuth()?.GroupMailBy, x => parseInt(x));
        item.reportexport = emailBase?.ReportExportID;
        item.language = emailBase?.Language ?? TranslationService.currentLanguage;
        item.mailframeid = emailBase?.MailFrameID ? parseInt(emailBase?.MailFrameID) : undefined;
        item.MessageID = emailBase?.MessageID;
        item.replytoid = prevMessageId ? parseInt(prevMessageId) : null;
        item.replacebody = !prevMessageId ? "0" : "1";
        item.activitytypeid = emailBase?.ActivityTypeID ?? CompanyService.getActivityTypes().find(x => x.Mail && x.MailDefault)?.ActivityTypeID;
        item.filter = emailBase?.IOFilter;
        item.quickfilter = emailBase?.QuickFilter ?? EmailInvoiceListType.Pending;
        item.includelink = emailBase?.IncludeLink;

        item.fromname = emailBase?.FromName ?? CompanyService.getUserFullName();
        item.from = emailBase?.FromName ?? CompanyService.getUserEmail();

        item.files = emailBase?.files.map(x => ({ id: x.id, name: x.fileName })) ?? [];
        item.draft = emailBase?.Draft ?? false;
        const orderByArray = emailBase?.Mail_OrderBy?.split(";");
        const orderByDescArray = emailBase?.Mail_OrderByOrderDesc?.split(";");
        item.orderby0 = OptionalMap(orderByArray?.[0], x => x.length > 0 ? parseInt(x) : undefined);
        item.orderby1 = OptionalMap(orderByArray?.[1], x => x.length > 0 ? parseInt(x) : undefined);
        item.orderby2 = OptionalMap(orderByArray?.[2], x => x.length > 0 ? parseInt(x) : undefined);
        item.orderbyorderdesc0 = (OptionalMap(orderByDescArray?.[0], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";
        item.orderbyorderdesc1 = (OptionalMap(orderByDescArray?.[1], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";
        item.orderbyorderdesc2 = (OptionalMap(orderByDescArray?.[2], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";

        // Will be set later
        // const temp = model.temp!;
        // temp.showPrevEmailBtn = Boolean(prevMessageId);
        return model;
    }
    const [isSending, setIsSending] = useState<string>("");
    const requiredManager = useRef<RequiredManager>(new RequiredManager()).current;

    const invoiceSelectedIds = useMemo(() => clientDetailContext?.invoiceIds ?? [], [clientDetailContext?.invoiceIds]);
    const invoiceSelectedAll = clientDetailContext?.invoiceAll ?? false;
    const features = useRef({} as Record<string, unknown>).current;
    const featuresCache: Record<string, ReturnType<EmailTabProps["getFeature"]>> = {};
    const getFeature: EmailTabProps["getFeature"] = <TKey extends keyof EmailComposeModel["item"],>(featureKey: TKey, offValue?: EmailComposeModel["item"][TKey]) => {
        if (featuresCache[featureKey] === undefined) {
            const setFeatures = () => {
                const newValue = features[featureKey + "isOn"] ? features[featureKey + "prevValue"] : offValue;
                setModel(model => {
                    const newModel = { ...model };
                    if (!newModel.item) {
                        newModel.item = {} as Partial<EmailComposeModel>["item"];
                    }
                    if (newModel.item) {
                        if (features[featureKey + "isOn"]) {
                            Object.assign(newModel.item, features[featureKey + "otherValues"]);
                        }
                        newModel.item[featureKey] = newValue as never;
                    }
                    return newModel;
                });
            };
            const toggleFeature = (isOn?: boolean | undefined) => {
                const value = model.item && model.item[featureKey];
                features[featureKey + "isOn"] = isOn !== undefined ? isOn : features[featureKey + "isOn"] === undefined ? (value === undefined || value === "off") : !features[featureKey + "isOn"];
                setFeatures();
            };
            const setFeatureValue = <TValueKey extends keyof EmailComposeModel["item"],>(key: TValueKey) =>
                (value: EmailComposeModel["item"][TValueKey]) => {
                    if (key === featureKey as string) {
                        features[featureKey + "prevValue"] = value;
                    } else {
                        const newValue = (features[featureKey + "otherValues"] ?? {}) as Record<string, unknown>;
                        newValue[key] = value;
                        features[featureKey + "otherValues"] = newValue;
                    }
                    setFeatures();
                };
            features[featureKey + "prevValue"] = model?.item?.[featureKey];
            features[featureKey + "isOn"] = features[featureKey + "isOn"] || (model?.item?.[featureKey] && model?.item?.[featureKey] !== offValue);

            featuresCache[featureKey] = {
                toggleFeature,
                setFeatureValue,
                isOn: Boolean(features[featureKey + "isOn"]),
            };
        }
        return featuresCache[featureKey];
    };

    useEffect(() => {
        if (invoiceSelectedIds.length > 0 || invoiceSelectedAll) {
            setModel(model => ({ ...model, ids: invoiceSelectedIds.join(","), item: { ...model.item, invoiceListType: EmailInvoiceListType.Selected, includeInvoiceList: true } } as EmailComposeModel));
        }
    }, [invoiceSelectedAll, invoiceSelectedIds]);

    const submit = async (type: "draft" | "send" = "send") => {
        if (isSending !== "") {
            return;
        }
        if (!requiredManager.validate()) {
            ToastService.showToast(translate.MissingRequiredFields, undefined, "warning");
            return;
        }
        setIsSending(type);
        model.item!.draft = type === "draft";
        const result = await ActivityService.setEmailNew(model as EmailComposeModel);
        if (result instanceof Error) {
            const messageError = (getError(result).message === "Fail-Hour") ? translate.CantScheduleSendingToPastDate : translate.ErrorProcessingRequest;
            showToast(messageError, undefined, "danger");
            setIsSending("");
        }
        else {
            showToast(type === "draft" ? translate.SavedChanges : translate.Sent, undefined, "success");
            onSubmit && onSubmit();
            setModel(getDefaultModel(props));
            setIsSending("");
            FloatingPanelService.hidePanel();
        }
    };

    const tabs: TabMenuProps<EmailTabProps>["items"] = useMemo(() => [
        { title: <><i className="fa-regular fa-file" /> {TranslationService.translate.Message}</>, component: EmailTabMessage },
        { title: <><i className="fa-regular fa-receipt" /> {TranslationService.translate.Invoices}</>, component: EmailTabInvoice },
        { title: <><i className="fa-regular fa-file-exclamation" /> {TranslationService.translate.Additionals}</>, component: EmailTabAdditional },
        { title: <><i className="fa-regular fa-eye" /> {TranslationService.translate.Preview}</>, component: EmailTabPreview }
    ], []);

    const tabProps: TabMenuProps<EmailTabProps>["props"] = {
        model,
        setModel,
        getFeature,
        requiredManager,
    };

    return (
        <>
            <div className="floatingBody p-0">
                <TabMenu items={tabs} props={tabProps} />
            </div>
            <FloatingPanelFooter>
                <>
                    <button className='btn btn-secondary' onClick={() => submit("draft")}>
                        {translate.Draft} {isSending === "draft" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>
                    <button className='btn btn-primary' onClick={() => submit()}>
                        {getFeature("scheduleddate").isOn ? translate.Program : translate.Send} {isSending === "send" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>
                </>
            </FloatingPanelFooter>
        </>
    );
};

