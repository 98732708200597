import { useEffect, useMemo, useRef, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import CompanyService, { Entities } from "../../../services/CompanyService";
import { AutocompleteSelect, CheckBoxEditor, DateTimePickerEditor, TextEditor } from "../../shared/components/Editors";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import Dropdown from "../../shared/components/Dropdown";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SwitchButton from "../../shared/components/SwitchButton";
import { EmailComposeModel, EmailTabProps } from "./EmailCompose";
import { EntityField } from "../../shared/entities/EntityField";
import EmailTemplateService from "../../../services/EmailTemplateService";
import { ToastService } from "../../shared/bootstrap/Toast";
import ModalService from "../../shared/bootstrap/Modal";
import ActivityService from "../../../services/ActivityService";
import { parseDate, restTimezone } from "../../../utils/ParseUtils";
import { Cast, OptionalMap, isNullOrWhitespace } from "../../../utils/Utils";
import ClientService from "../../../services/ClientService";
import { AutocompleteAsync } from "../../shared/bootstrap/AutocompleteAsync";
import { handleError, mapIfSuccess } from "../../../utils/RequestUtils";
import { Editor as EmailEditor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState, Modifier } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { ToolbarOptions } from "./ToolbarOptions";
import GroupService from "../../../services/GroupService";
import { StorageService, CacheEntity } from "../../../services/StorageService";
import AccountService from "../../../services/AccountService";
import { MultiFileUpload } from "../../shared/components/MultiFileUpload";

export const EmailTabMessage = ({ model, setModel, getFeature, requiredManager }: EmailTabProps) => {
    const setEmail = <TKey extends keyof EmailComposeModel["item"],>(key: TKey) =>
        (value: EmailComposeModel["item"][TKey]) =>
            setModel(model => {
                const newModel = { ...model };
                if (!newModel.item) {
                    newModel.item = {} as Partial<EmailComposeModel>["item"];
                }
                if (newModel.item) {
                    newModel.item[key] = value;
                }
                return newModel;
            });

    const setTemp = <TKey extends keyof EmailComposeModel["temp"],>(key: TKey) =>
        (value: EmailComposeModel["temp"][TKey]) =>
            setModel(model => {
                const newModel = { ...model };
                if (newModel.temp) {
                    newModel.temp[key] = value;
                }
                return newModel;
            });

    let feature = {} as ReturnType<typeof getFeature>;

    const setFeature = (featureKey: Parameters<typeof getFeature>[0]) => {
        feature = getFeature(featureKey);
    };
    setFeature("scheduleddate");
    const scheduleDateRef = useRef(model.item?.scheduleddate && OptionalMap(parseDate(model.item.scheduleddate), x => restTimezone(x).toJSON()));

    const onScheduleChange = requiredManager.makeRequiredWithId((x: string) => {
        feature.setFeatureValue("scheduleddate")(x && OptionalMap(parseDate(x), y => restTimezone(y).toJSON()));
    }, "scheduleddate");

    const getDefaultState = () => {
        const blocksFromHtml = htmlToDraft(model.item?.content ?? "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        convertToRaw(contentState);
        return EditorState.createWithContent(contentState);
    };

    const [withBCC, setWithBCC] = useState(Boolean(model.item?.bcc));
    const [withCC, setWithCC] = useState(Boolean(model.item?.cc));
    const { translate } = TranslationService;

    const defaultEditorState = useRef(getDefaultState());
    const editorRef = useRef<null | EditorType>(null);


    const emailTemplateOptions = useMemo(() =>
        CompanyService.getEmailTemplates().map(x => ({ value: x.MailTemplateID, text: x.Name })), []);
    const insertFieldOptions = useMemo(() => {
        const fieldsMap = [
            TranslationService.translate.Client,
            TranslationService.translate.Contact,
            TranslationService.translate.Comments,
            ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client).map(x => new EntityField(x)).map(x => x.title),
            TranslationService.translate.Amount,
            TranslationService.translate.DueAmount,
            TranslationService.translate.NotDueAmount,
            TranslationService.translate.InvoiceList,
            TranslationService.translate.Date,
            TranslationService.translate.FollowUpMail,
        ];
        const fields = fieldsMap.map(x => ({ text: x!, value: `[${x!}]` }));
        if (CompanyService.getCompanyAuthorization().includes("customerportalenabled")) {
            fields.push({ text: TranslationService.translate.CustomerSite, value: "[Portal]" });
        }
        return fields;
    }, []);
    const activityTypeOptions = useMemo(() => CompanyService.getActivityTypes()
        .filter(x => x.Mail)
        .map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName })), []);

    const groupSelectOptions = async (inputValue: string) => {
        let list: Array<{ value: string, label: string }> = [];
        const minInputLength = 2;
        if (!model.item?.personid || inputValue.length < minInputLength) { return list; }
        const result = await GroupService.search(model.item!.personid.toString(), inputValue);
        if (!(result instanceof Error)) {
            list = result.list.map(x => ({ value: x.data.toString(), label: x.value }));
        }
        return list;
    };
    const groupName = model.item?.groupName ?? (model.item?.groupid ? StorageService.getCache(CacheEntity.GroupName, model.item?.groupid) : undefined);

    const onTemplateChange = async (templateId?: number) => {
        if (!templateId) { return; }
        const resultPromise = EmailTemplateService.get(templateId);
        const replaceContent = async () => {
            const result = await resultPromise;
            if (result instanceof Error) {
                ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return;
            }
            const template = result.item;
            setModel(model => ({
                ...model,
                item: {
                    ...model.item,
                    bodyHtml: template.Content,
                    subject: template.Subject,
                    activitytypeid: template.ActivityTypeID,
                    activityTypeId: template.ActivityTypeID,
                    files: template.files.map(x => ({ name: x.fileName, id: x.id })),
                    templateId: templateId.toString(),
                },
            }) as Partial<EmailComposeModel>);
            updateEmailBody(template.Content);
        };
        if (Boolean(model?.item?.content?.length) || Boolean(model?.item?.subject?.length)) {
            ModalService.showDefaultModal({
                title: TranslationService.translate.ReplaceContent,
                message: TranslationService.translate.ReplaceContentBody,
                acceptButtonLabel: translate.Yes,
                onAcceptClick: replaceContent
            });
        } else {
            replaceContent();
        }
    };

    const changeBody = requiredManager.makeRequiredWithId((x: string) => { setEmail("content")(x); }, "content");
    const editorChangeBody = (content: string) => {
        changeContentOfEditor(editorRef.current!, content);
    }
    const updateEmailBody = async (content: string) => {
        changeBody(content);
        editorChangeBody(content);
    };

    const insertTextInEditor = async (text?: string) => {
        if (text === undefined) {
            return;
        }
        const newState = Modifier.insertText(defaultEditorState.current!.getCurrentContent(), defaultEditorState.current!.getSelection(), text);
        const editorState = EditorState.createWithContent(newState);
        editorRef.current!.onChange(editorState);
        const markup = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        changeBody(markup);
    };

    const loadPrevMail = async () => {
        if (!model.temp?.showPrevEmailBtn) { return model.item?.content ?? ""; }
        setTemp("showPrevEmailBtn")("loading");
        const result = await ActivityService.getEmailContent({ id: model!.item!.replytoid!.toString(), noheader: "0" });
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setTemp("showPrevEmailBtn")(true);
            return undefined;
        }
        setTemp("showPrevEmailBtn")(false);
        setEmail("replacebody")("0");
        const base = model.item?.content ? model.item?.content : "<br/>";
        const newEmailBody = base + "<hr />" + result.content;
        updateEmailBody(newEmailBody);
        return newEmailBody;
    };

    useEffect(() => {
        if (model.item?.content === undefined || model.item?.content === null) {
            const updateSignature = async () => {
                let content = "";
                const imageSignature = await handleError(AccountService.getAccountSignature());
                if (imageSignature.item === null) {
                    const result = await handleError(AccountService.getAccountSignatureText());
                    content = isNullOrWhitespace(result.signature) ? "" : ("\n\n\n\n\n\n<br><br><br><br><br>------------------<br>" + result.signature);
                }
                changeContentOfEditor(editorRef.current!, content);
                setModel(model => {
                    const newModel = { ...model };
                    if (!newModel.item) {
                        newModel.item = {} as Partial<EmailComposeModel>["item"];
                    }
                    if (newModel.item) {
                        newModel.item.content = content;
                    }
                    return newModel;
                });
            }
            updateSignature();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addresBarProps = { model, setEmail };

    const toolbar: ToolbarOptions = {
        options: [
            "fontSize",
            "colorPicker",
            "inline",
            "list",
            "textAlign",
            "link",
            "remove",
        ],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: [
                "bold",
                "italic",
                "underline",
            ],
            bold: { className: undefined, title: undefined },
            italic: { className: undefined, title: undefined },
            underline: { className: undefined, title: undefined },
            strikethrough: {
                className: undefined,
                title: undefined
            },
            monospace: { className: undefined, title: undefined },
            superscript: { className: undefined, title: undefined },
            subscript: { className: undefined, title: undefined }
        },
        blockType: {
            inDropdown: true,
            options: [
                "Normal",
                "H1",
                "H2",
                "H3",
                "H4",
                "H5",
                "H6",
                "Blockquote",
                "Code"
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            title: undefined
        },
        fontSize: {
            options: [8, 10, 12, 14, 18, 24, 36],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            title: undefined
        },
        fontFamily: {
            options: [
                "Arial",
                "Georgia",
                "Impact",
                "Tahoma",
                "Times New Roman",
                "Verdana"
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            title: undefined
        },
        list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["unordered", "ordered"],
            unordered: { className: undefined, title: undefined },
            ordered: { className: undefined, title: undefined },
            indent: { className: undefined, title: undefined },
            outdent: { className: undefined, title: undefined },
            title: undefined
        },
        textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["left", "center", "right"],
            left: { className: undefined, title: undefined },
            center: { className: undefined, title: undefined },
            right: { className: undefined, title: undefined },
            justify: { className: undefined, title: undefined },
            title: undefined
        },
        colorPicker: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
                "rgb(0 0 0)",
                "rgb(68,68,68)",
                "rgb(102,102,102)",
                "rgb(153,153,153)",
                "rgb(204,204,204)",
                "rgb(238,238,238)",
                "rgb(243,243,243)",
                "rgb(255,255,255)",
                "rgb(255,0,0)",
                "rgb(255,153,0)",
                "rgb(255,255,0)",
                "rgb(0,255,0)",
                "rgb(0,255,255)",
                "rgb(0,0,255)",
                "rgb(153,0,255)",
                "rgb(255,0,255)",
                "rgb(244,204,204)",
                "rgb(252,229,205)",
                "rgb(255,242,204)",
                "rgb(217,234,211)",
                "rgb(208,224,227)",
                "rgb(207,226,243)",
                "rgb(217,210,233)",
                "rgb(234,209,220)",
                "rgb(234,153,153)",
                "rgb(249,203,156)",
                "rgb(255,229,153)",
                "rgb(182,215,168)",
                "rgb(162,196,201)",
                "rgb(159,197,232)",
                "rgb(180,167,214)",
                "rgb(213,166,189)",
                "rgb(224,102,102)",
                "rgb(246,178,107)",
                "rgb(255,217,102)",
                "rgb(147,196,125)",
                "rgb(118,165,175)",
                "rgb(111,168,220)",
                "rgb(142,124,195)",
                "rgb(194,123,160)",
                "rgb(204,0,0)",
                "rgb(230,145,56)",
                "rgb(241,194,50)",
                "rgb(106,168,79)",
                "rgb(69,129,142)",
                "rgb(61,133,198)",
                "rgb(103,78,167)",
                "rgb(166,77,121)",
                "rgb(153,0,0)",
                "rgb(180,95,6)",
                "rgb(191,144,0)",
                "rgb(56,118,29)",
                "rgb(19,79,92)",
                "rgb(11,83,148)",
                "rgb(53,28,117)",
                "rgb(116,27,71)",
                "rgb(102,0,0)",
                "rgb(120,63,4)",
                "rgb(127,96,0)",
                "rgb(39,78,19)",
                "rgb(12,52,61)",
                "rgb(7,55,99)",
                "rgb(32,18,77)",
                "rgb(76,17,48)"
            ],
            title: undefined
        },
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"],
            link: { className: undefined, title: undefined },
            unlink: { className: undefined, title: undefined },
            linkCallback: undefined
        },
        emoji: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            emojis: [
                "😀",
                "😁",
                "😂",
                "😃",
                "😉",
                "😋",
                "😎",
                "😍",
                "😗",
                "🤗",
                "🤔",
                "😣",
                "😫",
                "😴",
                "😌",
                "🤓",
                "😛",
                "😜",
                "😠",
                "😇",
                "😷",
                "😈",
                "👻",
                "😺",
                "😸",
                "😹",
                "😻",
                "😼",
                "😽",
                "🙀",
                "🙈",
                "🙉",
                "🙊",
                "👼",
                "👮",
                "🕵",
                "💂",
                "👳",
                "🎅",
                "👸",
                "👰",
                "👲",
                "🙍",
                "🙇",
                "🚶",
                "🏃",
                "💃",
                "⛷",
                "🏂",
                "🏌",
                "🏄",
                "🚣",
                "🏊",
                "⛹",
                "🏋",
                "🚴",
                "👫",
                "💪",
                "👈",
                "👉",
                "👆",
                "🖕",
                "👇",
                "🖖",
                "🤘",
                "🖐",
                "👌",
                "👍",
                "👎",
                "✊",
                "👊",
                "👏",
                "🙌",
                "🙏",
                "🐵",
                "🐶",
                "🐇",
                "🐥",
                "🐸",
                "🐌",
                "🐛",
                "🐜",
                "🐝",
                "🍉",
                "🍄",
                "🍔",
                "🍤",
                "🍨",
                "🍪",
                "🎂",
                "🍰",
                "🍾",
                "🍷",
                "🍸",
                "🍺",
                "🌍",
                "🚑",
                "⏰",
                "🌙",
                "🌝",
                "🌞",
                "⭐",
                "🌟",
                "🌠",
                "🌨",
                "🌩",
                "⛄",
                "🔥",
                "🎄",
                "🎈",
                "🎉",
                "🎊",
                "🎁",
                "🎗",
                "🏀",
                "🏈",
                "🎲",
                "🔇",
                "🔈",
                "📣",
                "🔔",
                "🎵",
                "🎷",
                "💰",
                "🖊",
                "📅",
                "✅",
                "❎",
                "💯"
            ],
            title: undefined
        },
        embedded: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            embedCallback: undefined,
            defaultSize: {
                height: "auto",
                width: "auto"
            },
            title: undefined
        },
        image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            previewImage: false,
            alignmentEnabled: true,
            uploadCallback: undefined,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
                height: "auto",
                width: "auto"
            },
            title: undefined
        },
        remove: {
            className: undefined,
            component: undefined,
            title: undefined
        },
        history: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["undo", "redo"],
            undo: { className: undefined, title: undefined },
            redo: { className: undefined, title: undefined },
            title: undefined
        }
    };
    return (
        <>
            <div className="tabBody">
                <div className="row mb-2 g-0 align-items-center">
                    <div className="col-2">
                        <label className="form-label">
                            {TranslationService.translate.From}:
                        </label>
                    </div>
                    <div className="col-5 pe-2">
                        <form className="inputWithButtons">
                            <input className="form-control" defaultValue={model.item?.fromname}
                                readOnly={!CompanyService.getSetting("allowemailfromedit")}
                                onChange={(e) => setEmail("fromname")(e.target.value)}
                                placeholder={TranslationService.translate.Name} />
                        </form>
                    </div>
                    <div className="col-5 ps-2">
                        <form className="inputWithButtons">
                            <input className="form-control" defaultValue={model.item?.from}
                                readOnly={!CompanyService.getSetting("allowemailfromedit")}
                                onChange={(e) => setEmail("from")(e.target.value)}
                                placeholder={TranslationService.translate.Email} />
                            <div className="d-flex flex-nowrap gap-2">
                                {!withCC && <button className="btn btn-link" type="button" onClick={() => setWithCC(true)}>CC</button>}
                                {!withBCC && <button className="btn btn-link" type="button" onClick={() => setWithBCC(true)}>{TranslationService.translate.EmailBCC}</button>}
                            </div>
                        </form>
                    </div>
                </div>
                <AddressBar {...addresBarProps} emailProp={"to"} requiredManager={requiredManager}
                    label={TranslationService.translate.EmailTo} />
                {withCC && <AddressBar {...addresBarProps} emailProp={"cc"} label={"CC"} />}
                {withBCC && <AddressBar {...addresBarProps} emailProp={"bcc"}
                    label={TranslationService.translate.EmailBCC} />}
                <AddressBar {...addresBarProps} emailProp={"subject"} requiredManager={requiredManager}
                    label={TranslationService.translate.Subject} />
                <hr />
                <div className="row mb-4">
                    <div className="col-4">
                        <label className="form-label">
                            {TranslationService.translate.ActivityType}:
                        </label>
                        <Dropdown onChange={requiredManager.makeRequiredIf(CompanyService.getSetting("mandatoryemailtype"), setEmail("activitytypeid"), "activitytype")} items={activityTypeOptions}
                            optionLabel={TranslationService.translate.SelectActivityType}
                            value={model.item?.activitytypeid} />
                        <ValidationMessage onChange={requiredManager.getValidation("activitytype")} defaultValue={model.item?.activitytypeid} />
                    </div>
                    <div className="col-4">
                        <label className="form-label">
                            {TranslationService.translate.Template}:
                        </label>
                        <Dropdown onChange={onTemplateChange} items={emailTemplateOptions}
                            optionLabel={TranslationService.translate.SelectTemplate} />
                    </div>
                    <div className="col-4">
                        <label className="form-label">
                            {TranslationService.translate.InsertField}:
                        </label>
                        <Dropdown onChange={insertTextInEditor} items={insertFieldOptions}
                            optionLabel={TranslationService.translate.EmailInsertField} />
                    </div>
                </div>
                <div className={"row g-0 mb-3" + (model.temp?.showPrevEmailBtn ? " mce-editor-prev-message-margin" : "")}>
                    <label className="form-label">
                        {TranslationService.translate.Message}:
                    </label>
                    <EmailEditor
                        toolbar={toolbar}
                        defaultEditorState={defaultEditorState.current}
                        onEditorStateChange={(editorState) => {
                            const rawContentState = convertToRaw(editorState.getCurrentContent());
                            const markup = draftToHtml(rawContentState, {}, false);
                            changeBody(markup);
                            defaultEditorState.current = editorState;
                        }}
                        locale={TranslationService.currentLanguage}
                        ref={x => editorRef.current = Cast<EditorType>(x)}
                    />
                    {
                        /*
                         <EmailEditor
                            onInit={(_evt, editor) => {
                                if (toggleReloadRef.id === 2) {
                                    toggleReloadRef.fun();
                                    return;
                                }
                                toggleReloadRef.id = 2;    
                                addToolbarTranslations(editor);
                                emailBodyEditor.current.push(editor);
                                setTimeout(() => emailBodyEditor.current.forEach(x => x.setContent(model.item?.content ?? "")), 1500);
                                if (model.temp?.showPrevEmailBtn === undefined) {
                                    setTemp("showPrevEmailBtn")(Number.isInteger(model.item?.replytoid));
                                }
                            }}
                            // initialValue={(model.item?.content ?? "")}
                            apiKey="ezs0brrz4olo2b8evmjpybkbp6x7c4sk9bh7upj05tyd7rr5"
                            onChange={(_, editor) => changeBodyRef(editor.getContent())}
                            id={"editor-emailbody2"}
                            init={{
                                statusbar: false,
                                menubar: false,
                                plugins: "lists link",
                                height: "230",
                                toolbar: "fontsize forecolor bold italic underline bullist indent alignleft aligncenter alignright link unlink removeformat",
                                language: currentLanguage,
                                toolbar_location: "bottom",
                            }}
                            onPaste={onEmailEditorPaste}
                        /> */
                    }
                    {model.temp?.showPrevEmailBtn &&
                        <div style={{ height: 0 }}>
                            <button className="btn btn-sm btn-secondary email-ellipsis-dots"
                                style={model.temp?.showPrevEmailBtn === "loading" ? { padding: 0, paddingLeft: 10, paddingRight: 20 } : {}}
                                onClick={loadPrevMail}>{model.temp?.showPrevEmailBtn === "loading" ? <i className="fas fa-spinner-third fa-spin third ms-2"></i> : "..."}</button>
                        </div>}
                    <ValidationMessage onChange={requiredManager.getValidation("content")} defaultValue={model.item?.content} />
                </div>
                <div className="row justify-content-start gap-3 flex-wrap mb-4 g-0 align-items-center alert alert-info ">
                    <div className="w-auto"><MultiFileUpload onFilesChange={setEmail("files")} files={model?.item?.files ?? []} canEdit={true} /></div>
                    <div className="w-auto"><CheckBoxEditor onChange={setEmail("copyme")} message={TranslationService.translate.SendMeACopy} defaultValue={model.item?.copyme} /></div>
                    <div className="w-auto"><SwitchButton label={TranslationService.translate.Program} onChange={() => { feature.toggleFeature(); onScheduleChange(scheduleDateRef.current); }} defaultValue={feature.isOn} /></div>
                    <div className="col-3" style={{ height: 40 }}>{feature.isOn && <DateTimePickerEditor onChange={x => { scheduleDateRef.current = x; onScheduleChange(x); }} minDate={new Date()}
                        defaultValue={model.item?.scheduleddate && OptionalMap(parseDate(model.item.scheduleddate), x => restTimezone(x).toJSON())} />}
                    </div>
                    <ValidationMessage
                        onChange={onScheduleChange}
                        message={translate.CalendarValidatorTip} defaultValue={scheduleDateRef.current}
                        validationMethod={feature.isOn ?
                            (value: string | undefined) => !feature.isOn || ((parseDate(value)?.getTime() ?? 0) > Date.now())
                            : () => true} />
                </div>
                {CompanyService.getGroupName()?.length > 0 && <div className="row mb-1">
                    <label className="col-2 font-weight-normal col-form-label">
                        {CompanyService.getGroupName()}:
                    </label>
                    <div className="col-10">
                        <AutocompleteSelect loadOptions={groupSelectOptions} onChange={x => setEmail("groupid")(x?.value)} defaultVal={model.item?.groupid && groupName ? { value: model.item?.groupid, label: groupName } : undefined} />
                    </div>
                </div>}
            </div>
        </>
    );
};

type AddressBarProps = {
    model: Partial<EmailComposeModel>,
    setEmail: (email: keyof EmailComposeModel["item"]) => (value: string) => void,
    emailProp: keyof EmailComposeModel["item"],
    label: string,
    requiredManager?: RequiredManager,
};

const AddressBar = ({ model, setEmail, emailProp, label, requiredManager }: AddressBarProps) => {
    const onChange = useMemo(() => {
        const baseOnChange = setEmail(emailProp);
        return requiredManager ? requiredManager.makeRequiredWithId(baseOnChange, emailProp) : baseOnChange;
    }, [requiredManager, emailProp, setEmail]);
    const loadItems = useMemo(() => async (x: string) => mapIfSuccess(
        await ClientService.getEmailsAutocomplete(model.item!.personid!, x),
        x => x.suggestions.map(y => ({ text: y.data, value: y.value }))
    ), [model.item]);
    const value = model.item?.[emailProp] as string;
    return (
        <div className="row mb-2 g-0 align-items-center">
            <div className="col-2">
                <label className="form-label">
                    {label}:
                </label>
            </div>
            <div className="col-10">
                {emailProp === "subject" ?
                    <TextEditor onChange={onChange} value={value} /> :
                    <AutocompleteAsync loadItems={loadItems} onChange={onChange} defaultValue={value} />
                }
                {requiredManager && <ValidationMessage onChange={onChange} defaultValue={value} />}
            </div>
        </div>
    );
};

type EditorType = { onChange: (state: EditorState) => void };
function changeContentOfEditor(editor: EditorType, content: string) {
    const blocksFromHtml = htmlToDraft(content ?? "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    editor.onChange(editorState);
}