import { useState, useEffect, useRef } from "react";
import FileService from "../../../services/FileService";
import { TranslationService } from "../../../services/TranslationService";
import { ToastService } from "../bootstrap/Toast";
import TooltipComponent from "../TooltipComponent";
import { FileApi, FileListEditorParams } from "./Editors";
import { DomEventService } from "../../../services/DomEventService";

export const MultiFileUpload = (params: FileListEditorParams) => {
    const [files, setFiles] = useState<FileApi[]>([]);
    const [showDrag, setShowDrag] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropRef = useRef<HTMLDivElement | null>(null);
    const { onFilesChange, fileDelete } = params;
    const downloadFile = params.downloadFile ?? FileService.download;
    const updateFiles = (files: FileApi[]) => {
        setFiles([...files]);
        if (onFilesChange) {
            onFilesChange(files);
        }
    };

    const dragLeaveTimeout = useRef<number>(0);
    useEffect(() => {
        const subscriptions = [
            DomEventService.GetBodyEvents("dragover").subscribe(x => {
                if (x.dataTransfer?.types.includes("Files")) {
                    x.preventDefault();
                    clearTimeout(dragLeaveTimeout.current);
                    setShowDrag(true);
                }
            }),
            DomEventService.GetBodyEvents("dragleave").subscribe(x => {
                if (x.dataTransfer?.types.includes("Files")) {
                    clearTimeout(dragLeaveTimeout.current);
                    dragLeaveTimeout.current = setTimeout(() => setShowDrag(false), 100, undefined);
                }
            }),
            DomEventService.GetBodyEvents("drop").subscribe(x => {
                if (x.dataTransfer?.types.includes("Files")) {
                    if (x.target === dropRef.current) {
                        x.preventDefault();
                        const files = [...x.dataTransfer.items]
                            .map(item =>
                                item.kind === "file" ? item.getAsFile() : undefined
                            )
                            .filterFalsey();
                        if (files.length === 0) {
                            files.push(...x.dataTransfer.files);
                        }
                        onFileChange(files);
                    }
                    clearTimeout(dragLeaveTimeout.current);
                    setShowDrag(false);
                }
            }),
        ];
        return () => subscriptions.forEach(x => x.unsubscribe());
    });

    useEffect(() => {
        setFiles(params.files ?? []);
    }, [params.files]);

    const onFileChange = async (fileList: File[]) => {
        if (!fileList.length) { return; }
        const filesToUpload = [...fileList].filter(file => {
            if (file.size > 24000000) {
                ToastService.showToast(TranslationService.translate.FileSizeError, undefined, "danger");
                return false;
            }
            return true;
        });
        setLoading(true);
        const result = await FileService.multiupload(filesToUpload);
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            return false;
        }
        setLoading(false);
        updateFiles([...files, ...result.files.map(x => ({ id: x.id, name: x.filename }))]);
    };

    const removeFile = (id: string) => {
        updateFiles([...files.filter(x => x.id !== id)]);
        if (fileDelete) { fileDelete(id); }
    };
    return (
        <>
            {files.length > 0 &&
                <ul className="list-unstyled my-2">
                    {files.map(x =>
                        <li key={x.id}>
                            <label className="form-label-detail pe-2">
                                {x.name}
                            </label>
                            <TooltipComponent title={TranslationService.translate.Download}>
                                <button className="btn btn-intiza px-2" onClick={() => downloadFile(x.id, x.name)}>
                                    <i className="far fa-file-arrow-down"></i>
                                </button>
                            </TooltipComponent>
                            {params.canEdit &&
                                <TooltipComponent title={TranslationService.translate.Delete}>
                                    <button className="btn btn-intiza px-2" onClick={() => removeFile(x.id)}>
                                        <i className="far fa-trash"></i>
                                    </button>
                                </TooltipComponent>
                            }
                        </li>
                    )}
                </ul>
            }
            {params.canEdit &&
                <>
                    {showDrag ?
                        <div ref={dropRef} className="drop-files-box">
                            {TranslationService.translate.DropYourFilesHere}
                        </div > :
                        <label className="btn-intiza has-pointer text-start pl-0 removeOnPrint">
                            <input type="file" multiple style={{ display: "none" }} onChange={(e) => onFileChange([...e.target.files ?? []])} />
                            <i className="far fa-paperclip me-2"></i>
                            {TranslationService.translate.SelectOrDragAFile}
                            {loading && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </label>}
                </>
            }
        </>
    );
};
