import {
  ReactChild,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useHistory } from "react-router";

import AuthenticationContext from "../shared/AuthenticationContext";
import ErrorContext, { ErrorProvider } from "../shared/ErrorContext";
import AuthenticationService, {
  ValidLogonResponse,
} from "../../services/AuthenticationService";
import StaticHeader from "./components/StaticHeader";
import Loading from "../shared/components/Loading";
import logo from "../../assets/logo-default.png";
import Footer from "../shared/Footer";

const BodyContainer = (props: {
  children:
  | boolean
  | ReactChild
  | ReactFragment
  | ReactPortal
  | null
  | undefined;
}) => {
  return (
    <div
      className={
        "d-flex flex-column justify-content-center align-items-center flex-grow-1 py-5"
      }
      style={{ backgroundColor: "#f2f1f6" }}
    >
      <img
        src={logo}
        alt="intiza"
        width={"150px"}
        height={"auto"}
        className={"d-none mb-3 mt-1"}
      />
      {props.children}
    </div>
  );
};

const HomeErrorMessage = () => {
  const { currentError } = useContext(ErrorContext);

  return (
    <div
      id="errorMsj"
      className="incorrect"
      style={{
        color: "red",
        fontSize: "14px",
        textAlign: "center",
        margin: "0px 0px 10px",
      }}
      dangerouslySetInnerHTML={{ __html: currentError }}
    />
  );
};

function LogonAuthToken(props: { lang: string }): ReactElement {
  const { doLogout } = useContext(AuthenticationContext);
  const { setCurrentAuthentication } = useContext(AuthenticationContext);
  const history = useHistory();

  const loginToken =
    new URLSearchParams(history.location.search).get("token") ?? "";
  const companyGuid =
    new URLSearchParams(history.location.search).get("guid") ?? "";

  const companyLogin = useCallback(
    async (userData: ValidLogonResponse) => {
      const companyData = await AuthenticationService.companyLogin(
        userData,
        true
      );
      if (companyData instanceof Error) {
        history.push(`/${props.lang}/logout`);
      } else {
        setCurrentAuthentication(companyData);
        const returnUrl = new URLSearchParams(history.location.search).get(
          "returnUrl"
        );
        history.push(returnUrl ? returnUrl : `/${props.lang}/company`);
        return;
      }
    },
    [history, props.lang, setCurrentAuthentication]
  );

  useEffect(() => {
    const checkLoginToken = async () => {
      if (loginToken !== "") {
        doLogout();
        const logonResponse = {
          response: "OK",
          token: loginToken,
          lastcompanyguid: companyGuid,
        } as ValidLogonResponse;

        AuthenticationService.saveUserPayload(logonResponse);
        await companyLogin(logonResponse);
      } else {
        history.push(`/${props.lang}/logout`);
      }
    };
    checkLoginToken();
  }, [companyGuid, companyLogin, doLogout, history, loginToken, props.lang]);

  return (
    <ErrorProvider>
      <div className="old-page">
        <StaticHeader lang={props.lang} />
        <BodyContainer>
          <div className="pt-5">
            <div className="container logon" id="signin">
              <HomeErrorMessage />
              <div className="d-flex flex-column justify-content-center">
                <Loading className=""></Loading>
                <h3 className="ms-2 text-center">Ingresando</h3>
              </div>
            </div>
          </div>
        </BodyContainer>
        <Footer  />
      </div>
    </ErrorProvider>
  );
}

export default LogonAuthToken;
